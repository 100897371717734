import Swal from "sweetalert2";

export const showFullImagePopup = (imageUrl: string) => {
    Swal.fire({
        imageUrl: imageUrl,
        imageWidth: 800,
        padding: 0,
        customClass: {
            image: 'm-0',
            header: 'p-0'
        },
        animation: false,
        showCancelButton: false, // There won't be any cancel button
        showConfirmButton: false // There won't be any confirm button
    });
}