import { createControlComponent } from "@react-leaflet/core";
import { Control } from "leaflet";

const controlWatermark = Control.extend({
  onAdd: function (map) {
    var newDiv = document.createElement('div');
    newDiv.className = 'leaflet-bar btn btn-sm btn-light';
    newDiv.innerHTML = '<i class="fas fa-location-arrow"></i>';
    newDiv.onclick = () => map.locate({setView : true, enableHighAccuracy: true});
    return newDiv;
  },

  onRemove: function (map) {},
});

export const LocateButton = createControlComponent(
  (props) => new controlWatermark(props)
);