import React, { useMemo, useState } from 'react';
import Parse from 'parse';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import PageWithNavbar from '../../base/components/PageWithNavbar';
import PageErrorFetchingData from '../../base/components/PageErrorFetchingData';
import { GET_SINGLE_SPOT } from '../../base/queries';

import './index.css';

import { Spot } from '../../base/types';
import PageWithError from '../../base/components/PageWithError';
import SpotCheckIns from './components/SpotCheckIns';
import PreviewMap from './components/PreviewMap';

import { buildGoogleMapsLink, buildMapLocationLink, buildWazeLink, capitalize, formatDate, formatLocation } from '../../base/helpers';
import { CHECK_IN_ROUTE, LANDING_ROUTE, PROFILE_ROUTE } from '../../base/routes';
import Spinner from '../../base/components/Spinner';
import { USER_ROLE_REVIEWER } from '../../base/constants';

const addToFavorites = (spotId: string) => {
  let newSpot = new (Parse.Object.extend('Spot'))();
  newSpot.id = spotId;
  Parse.User.current().relation('favoriteSpots').add(newSpot);
  Parse.User.current().save()
    .then(
      () => {
        Swal.fire({
          icon: 'success',
          text: 'Spot successfully added to favorites.',
        });
      }
    )
    .catch(console.error);
}

const approveSpot = (spotId: string) => {
  let spot = new (Parse.Object.extend('Spot'))();
  spot.id = spotId;
  spot.set('status', 'published');
  spot.save().then(() => window.location.reload()).catch(console.error);
}

const declineSpot = (spotId: string, reason: string) => {
  let spot = new (Parse.Object.extend('Spot'))();
  spot.id = spotId;
  spot.set('status', 'declined');
  spot.set('decline_reason', reason);
  spot.save().then(() => window.location.reload()).catch(console.error);
}

const SpotInfo = () => {
  let [userRole, setUserRole] = useState<string>('not_defined');

  let { id } = useParams<{id: string}>();
  
  useMemo(() => {

    Parse.User.current()?.get('role')?.fetch().then((role) => {
      if (role) {
        setUserRole(role.get('name'));
      }
    });
  }, []);
  const { loading, error, data } = useQuery(GET_SINGLE_SPOT, {
    variables: {
      spotId: id
    },
    fetchPolicy: "network-only" // when navigating back to this page - fetch fresh data
  });

  if (loading) return <PageWithNavbar><Spinner /></PageWithNavbar>;
  if (error) return <PageErrorFetchingData error={error} />;
  if (!data.spot) {
    return <PageWithError><p><h3 className="text-center">Spot not found</h3> How did you got here?</p></PageWithError>;
  }

  const spot: Spot = data.spot;

  return <PageWithNavbar 
      pageTitle={spot.title + ' - selfie spot'} 
      pageDescription={spot.description}
      pageImageUrl={spot.image?.url.replace('graphq', 'parse')}
      pageAuthor={spot.author.displayName}
    >
    <div className="container spot-page bg-white shadow-sm pt-0 pb-3 py-md-3 mx-auto">
      { spot.status === 'pending' && 
        <section className="pending-alert mt-3 mt-md-0">
          <div className="alert alert-danger alert-outline-black" role="alert">
            <h4 className="alert-heading"><i className="fas fa-exclamation-triangle"></i> Pending spot</h4>
            <p>This spot is not yet approved. Spot information can be incorrect. Please don't check-in at this spot.</p>
          </div>
          
          { userRole === USER_ROLE_REVIEWER && 
            <div className="alert alert-danger alert-outline-black text-center" role="alert">
                Review spot  
                <button className="btn ml-1 btn-sm btn-outline-success" onClick={() => approveSpot(id)}>Approve</button>
                <button className="btn ml-1 btn-sm btn-outline-danger" onClick={() => declineSpot(id, prompt('What is decline reason?'))}>Decline</button>
            </div>
          }
        </section>
      }

      <section className="mt-0 mx-n3 mx-md-0">
        <img src={spot.image?.url.replace('graphq', 'parse') ?? '/img/placeholder.jpg'} alt={spot.title} loading="lazy" className="img-fluid rounded w-100" />
      </section>

      <section className="description">
        <h2>{spot.title}</h2>
        <p>
          {spot.description}
        </p>
        { spot.useful_link && 
          <p>
            More info <a className="link" href={spot.useful_link}>here</a>
          </p>
        }
      </section>

      { spot.type === 'challenge' && spot.task &&
        <section className="task">
          <i className="fas fa-flag"></i> <b>Challenge</b> <br/>{ spot.task }
        </section>
      }

      { spot.type === 'hunt' && spot.radius &&
        <section className="task">
          <i className="fas fa-search"></i> <b>Hunt</b> <br/> Search within { spot.radius } meters radius around map pin.
        </section>
      }

      <section className="tags">
        <p>
          { spot.tags.map(tag => <a key={tag.value} className="btn btn-sm btn-outline-warning" href={'/search/#' + encodeURIComponent(tag.value)}>#{tag.value}</a>)}
        </p>
      </section>

      <section className="row">
        <div className="col-12 col-md-6">
          <dl className="row no-gutters">
            <dt className="col-4">ID</dt>
            <dd className="col-8 text-right">{spot.objectId}</dd>

            <dt className="col-4">Added</dt>
            <dd className="col-8 text-right">{formatDate(spot.createdAt)}</dd>

            <dt className="col-4">Author</dt>
            <dd className="col-8 text-right">
              <a className="link" href={PROFILE_ROUTE.replace(':id', spot.author.objectId)} >{spot.author.displayName}</a>
            </dd>

            <dt className="col-4">Type</dt>
            <dd className="col-8 text-right">{capitalize(spot.type)}</dd>

            <dt className="col-4">Status</dt>
            <dd className="col-8 text-right">{capitalize(spot.status)}</dd>
            
            <dt className="col-4">Location</dt>
            <dd className="col-8 text-right">{formatLocation(spot.location)}</dd>
          </dl>
        </div>
        <div className="col-12 col-md-6">
          <PreviewMap spot={spot} />
        </div>
      </section>

      <section className="actions rounded w-100 text border shadow-sm p-2 text-center ">
        <a href={CHECK_IN_ROUTE.replace(':id', id)} className="btn mr-1 my-1 btn-sm btn-warning">
          <i className="fas fa-check"></i> Check In
        </a>
        <a href="#" onClick={(event) => { event.preventDefault(); addToFavorites(spot.objectId) }} className="btn mr-1 my-1 btn-sm btn-outline-dark" title="Add to favorites">
          <i className="far fa-star"></i> Add to favorites
        </a>
        {/* 
        <a href="#" className="btn mr-1 my-1 btn-sm btn-outline-dark" title="Export">
          <i className="fas fa-download"></i> Export
        </a> */}
        <a href={buildGoogleMapsLink(spot.location)} target="_blank" rel="noreferrer" className="btn mr-1 my-1 btn-sm btn-outline-dark" title="Open in Google Maps">
          <i className="fas fa-map-marked"></i> Open In Maps
        </a>
        <a href={buildWazeLink(spot.location)} target="_blank" rel="noreferrer" className="btn mr-1 my-1 btn-sm btn-outline-dark" title="Open in Waze">
          <i className="fab fa-waze pr-1"></i> Open In Waze
        </a>
        <a href={buildMapLocationLink(spot.location)} className="btn mr-1 my-1 btn-sm btn-outline-dark" title="Open on map">
        <i className="fas fa-map-marker"></i> Open on map
        </a>
      </section>
      
      { 
        Parse.User.current() 
          ? <SpotCheckIns checkinsCount={spot.checkins_count} likesCount={spot.likes_count} spotId={id} />
          : <section className="check-ins mt-5">
              <h5 className="pb-2 mb-0 border-bottom">
                  Check-ins<span className="metrics ml-2 float-right"><i className="fas fa-check"></i>{ spot.checkins_count }<i className="ml-2 far fa-heart"></i>{ spot.likes_count }</span>
              </h5>
              <div className="w-100 text-center p-4">
                <p className="text-muted"><a className="link" href={LANDING_ROUTE}>Sign in / Sign up</a> to view check-ins</p>
              </div>
            </section>
      }
      
    </div>
  </PageWithNavbar>
}

export default SpotInfo;
