import React from 'react';
import ReactDOM from 'react-dom';
import hello from 'hellojs';
import Parse from 'parse';
import { BrowserRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

/*
 * Bootstrap
 */
import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './index.css';

import Router from './base/components/Router';
import reportWebVitals from './reportWebVitals';

import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { LANDING_ROUTE } from './base/routes';

Sentry.init({
  dsn: "https://0e741ac0dab241e286a1cdfbf3ab65af@o909918.ingest.sentry.io/5844881",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.25,
});

/*
 * Parse
 */
Parse.initialize(process.env.APP_ID, process.env.JS_KEY);
Parse.serverURL = process.env.SERVER_URL;
Parse.secret = process.env.APP_SECRET;
Parse.enableEncryptedUser();
/*
 * Hellojs
 */ 
hello.init({
  // facebook: '970014330241464',
  facebook: process.env.NODE_ENV !== 'development' ? '970014330241464' : '903853166968936',
  google: '28642620364-l8qpsdktbhgbfrt482k2mcqiqvvi19ml.apps.googleusercontent.com'
}, {redirect_uri: '/'});

/*
 * Mixpanel
 */
if (process.env.NODE_ENV !== 'development') {
  mixpanel.init('38308d95fa8192cf41058e7faddf5932', {debug: true}); 
}

/*
 * Apollo
 */
const httpLink = createHttpLink({
  uri: process.env.GRAPHQL_URL
});

let customHeaders = {
  "X-Parse-Application-Id": process.env.APP_ID,
  "X-Parse-Javascript-Key": process.env.JS_KEY
}
if (Parse.User.current()) {
  customHeaders['X-Parse-Session-Token'] = Parse.User.current()?.getSessionToken();
}
 
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...customHeaders
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  try {
    if (networkError['result'].code === 209) {
      console.log(JSON.stringify(networkError));
      hello('google').logout();
      Parse.User.logOut().then(() => {
        window.location.href = LANDING_ROUTE;
      });
    }
  } catch (err) {

  }
});

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          checkIns: relayStylePagination(),
          spots: relayStylePagination(),
        },
      },
      User: {
        fields: {
          favoriteSpots: relayStylePagination(),
        }
      }
    },
  }),
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
  },
});

/*
 * React
 */

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
