import React from 'react';

import PageWithError from './PageWithError';

interface Props {
  error?: Error
}

const PageErrorFetchingData = (props: Props) => {
  return <PageWithError error={props.error}>
    <p>
      <h3>Problem fetching data ¯\_(ツ)_/¯</h3>
      Try reloading the page or <a href="mailto:selfiehuntapp@gmail.com?subject=Bug - can't fetch data"><u>submit the bug</u></a>
    </p>
  </PageWithError>;
}

export default PageErrorFetchingData;