import React from 'react';

import './index.css';

import PageWithNavbar from '../../base/components/PageWithNavbar';
import * as Routes from '../../base/routes';

const HowToPlay = () => <PageWithNavbar>
  <div className="container narrow-container bg-white shadow-sm rounded p-3 mt-3">
    <h3>How to play</h3>
    <p>
      </p><ol>
        <li>Choose a spot using <a href={Routes.MAP_ROUTE}>map page</a> or <a href={Routes.SEARCH_ROUTE}>search page</a> or <a href={Routes.HOME_ROUTE}>home page</a></li>
        <li>Visit spot</li>
        <li>Take a selfie</li>
        <li>Check-in by uploading selfie</li>
      </ol>
    <p></p>
    <p>
      Main goal of the SelfieHunt is to find interesting places around the globe - for travel, photography and just for fun.
      Find spot, visit it, take selfie and check-in! That's it.
    </p>
    <p>
      Easiest tools to find spots around you is to use <a href={Routes.MAP_ROUTE}>map page</a> and <a href={Routes.SEARCH_ROUTE}>search page</a>.
      Another option is <a href={Routes.HOME_ROUTE}>home page</a>, where spots are grouped by most recent or most popular order.
    </p>

    <h5 className="mb-2">Spot types</h5>
    <div className="mb-2"><img className="title-icon" alt="Regular Spot" title="Regular Spot" src="img/icon-spot.png" /> Regular spot - simple spot, where map pin stands exactly on a place where spot located, description of the post gives all necessary information about location and why it can be interesting.</div>
    <div className="mb-2"><img className="title-icon" alt="Hunt Spot" title="Hunt Spot" src="img/icon-hunt.png" /> Hunt spot - radius shows where the spot could be located, spot could found using details provided in description.</div>
    <div className="mb-2"><img className="title-icon" alt="Challenge Spot" title="Challenge Spot" src="img/icon-challenge.png" /> Challenge - map pin stands on a spot where some specific selfie should be taken - in some pose, with some object, etc.</div>
  </div>
</PageWithNavbar>


export default HowToPlay;
