import React, { useState } from "react";
import Parse from 'parse';
import { Marker, Popup } from "react-leaflet";
import L from 'leaflet';

import { Spot } from "../../../base/types";
import { SINGLE_SPOT_ROUTE } from "../../../base/routes";

let getIconUrl = (type: string, isVisited: boolean) => {
    if (isVisited) {
        return '/img/pin-visited.png';
    }
    
    if (type === 'challenge') {
        return  '/img/pin-challenge.png';
    } else if (type === 'hunt') {
        return  '/img/pin-hunt.png';
    }
    return '/img/pin-spot.png';
};

let getIconClassName = (type: string, isVisited: boolean, isFavorite: boolean) => {
    if (isVisited) {
        return 'spot-icon spot-icon-visited spot-icon-' + type;
    }
    if (isFavorite) {
        return 'spot-icon spot-icon-favorite spot-icon-' + type;
    }
    return 'spot-icon spot-icon-' + type;
};

let getPinIcon = (props: Props) => {
    return L.icon({
        iconUrl: getIconUrl(props.spot.type, props.isVisited),
        iconSize: [35, 48], // size of the icon
        iconAnchor: [15, 40], // point of the icon which will correspond to marker's location
        popupAnchor: [2, -37] // point from which the popup should open relative to the iconAnchor
      });
}

let getThumbnailIcon = (props: Props, isFavorite: boolean) => {
    let markerImage = new Image();
    markerImage.src = getIconImage(props.spot);
    markerImage.setAttribute('loading', 'lazy');
    return L.divIcon({
        className: getIconClassName(props.spot.type, props.isVisited, isFavorite),
        html: markerImage,
        iconUrl: getIconImage(props.spot),
        iconSize: [60, 60], // size of the icon
        popupAnchor: [0, -30] // point from which the popup should open relative to the iconAnchor
    });
}

let getImage = (spot: Spot) => spot.thumbnail?.url.replace('graphq', 'parse') 
    ?? spot.image?.url.replace('graphq', 'parse') 
    ?? '/img/placeholder.jpg';

let getIconImage = (spot: Spot) => spot.icon?.url.replace('graphq', 'parse') 
    ?? spot.thumbnail?.url.replace('graphq', 'parse') 
    ?? '/img/placeholder.jpg';

interface Props {
    spot: Spot
    isVisited: boolean
    isFavorite: boolean
    showThumbnailIcon: boolean
};

const LeafletMarker = (props: Props) => {
    const [ isFavorite, setIsFavorite ] = useState<boolean>(props.isFavorite);   
    let icon = props.showThumbnailIcon ? 
        getThumbnailIcon(props, isFavorite) : 
        getPinIcon(props);

    const markFavorite = (spotId: string, add: boolean) => {
        let newSpot = new (Parse.Object.extend('Spot'))();
        newSpot.id = spotId;
        if (add) {
            Parse.User.current().relation('favoriteSpots').add(newSpot);
        } else {
            Parse.User.current().relation('favoriteSpots').remove(newSpot);
        }
        Parse.User.current().save()
            .then(()=> {setIsFavorite(add)})
            .catch(console.error);
    }

    return <Marker 
        key={props.spot.objectId} 
        position={[props.spot.location.latitude, props.spot.location.longitude]}
        icon={icon}>
        <Popup autoPan={false}>
            <div className="card map-popup-card">
                <a className="link" href={SINGLE_SPOT_ROUTE.replace(':id', props.spot.objectId)}>
                    <img src={getImage(props.spot)} className="card-img-top" alt={props.spot.title} />
                </a>
                <div className="card-body">
                    <h6 className="card-title"><a className="link" href={SINGLE_SPOT_ROUTE.replace(':id', props.spot.objectId)}>{props.spot.title}</a></h6>
                    <div className="metrics">
                            {/* Not sure yet which version to use */}
                            {/* <i className="fas fa-check" />4
                            <i className="ml-2 far fa-heart" />2 */}
                        <div className="metrics">
                            <i className="fas fa-check"></i>{props.spot.checkins_count}
                            <i className="ml-2 far fa-heart"></i>{props.spot.likes_count}
                            
                            <a href="#" onClick={(event) => { event.preventDefault(); markFavorite(props.spot.objectId, !isFavorite) }} className="float-right " title="Add to favorites">
                                <i className={isFavorite ? "far fa-star star-favorite" : "far fa-star star-not-favorite"}></i>
                            </a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Popup>
    </Marker>;
}
export default LeafletMarker;