import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import Parse from 'parse';
import { redirectIfWebView } from '../helpers';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  redirectIfWebView();
  let isLoggedIn = !!Parse.User.current();

  if (!isLoggedIn) {
    sessionStorage.setItem('route_before_login', props.path.toString() + (props.location.hash ?? ''));
  }

  return isLoggedIn 
    ? <Route {...props} component={props.component} render={undefined} />
    : <Redirect push={true} to="/" />
}

export default PrivateRoute;