import React from 'react';

import PageWithNavbar from '../../base/components/PageWithNavbar';
import * as Routes from '../../base/routes';

const HowToSubmitSpot = () => <PageWithNavbar>
  <div className="container narrow-container bg-white shadow-sm rounded p-3 mt-3">
    <h3 className="mb-3">How to submit a spot</h3>
    <div><img className="mw-100 rounded" src="img/duck-selfies.jpg" alt="People making selfies with ducks" /></div>
    <p className="mt-3">
      <h5>Rules for contributing spot 🗿</h5>
      Most coolest places are those not mentioned in travel guides and looking perfectly on a selfie.
      Make sure location and selfie meets following rules. Every spot will be reviewed.
      <div className="mt-2">🗼 Location:</div>
      <ul>
        <li>Is publicly available and easy to access</li>
        <li>Is safe to visit</li>
        <li>Is not temporary</li>
        <li>There are at least 70 meteres till existing closest spots</li>
        <li>Getting to the spot and making selfies doesn't break local laws</li>
        <li>Spot doesn't promote any business or brand (we have separate spot type for that)</li>
      </ul>
      <div>🤳 Selfie: </div>
      <ul>
        <li>Face is clearly visible</li>
        <li>There is one person on selfie and it's you</li>
        <li>Spot is recognizable</li>
        <li>Photo belongs to you</li>
      </ul>
    </p>
    <p>
      <button className="btn btn-outline-secondary" data-toggle="collapse" aria-expanded="false" data-target="#selfieTips" aria-controls="selfieTips">Show selfie tips ✨</button>
      <p className="collapse multi-collapse mt-2 p-2 border rounded" id="selfieTips">  
        Here's few tips for making better selfie:
        <ul>
          <li>Experiment with different angles, come closer or farther, make sure both - you and the spot are well highlighted and fit well on the photo</li>
          <li>Use tools. Selfie stick, gimbal, timer, remote control clicker, tripod, light ring, and others could help capture creative selfies with bigger objects or from unusual angles</li>
          <li>Lighting. Natural soft light, indirect light are better for photos. Sunny days bring more shades on photos, but cloudy makes light more evenly spread on photo. Professionals say that the best time to take selfies are couple hours after sunrise and the couple hours before sunset</li>
          <li>Smile. It's optional, but makes every shot a bit better 😊</li>
        </ul>
      </p>
    </p>
    <p className="mt-3">
      <h5>Pick a spot type</h5>
      <a className="btn btn-outline-warning btn-lg btn-block" href={Routes.SUBMIT_REGULAR_SPOT_ROUTE}>
        <img className="title-icon" alt="Regular Spot" title="Regular Spot" src="img/icon-spot.png" /> Regular spot
        <br/><small>Traditional spot, where map pin stands exactly on a place where spot located and description of the post gives all necessary information about location.</small>
      </a>
      <a className="btn btn-outline-info btn-lg btn-block" href={Routes.SUBMIT_HUNT_SPOT_ROUTE}>
        <img className="title-icon" alt="Hunt Spot" title="Hunt Spot" src="img/icon-hunt.png" /> Hunt spot
        <br/><small>Spot is hidden, map pin shows approximate location. Spot can be found by following instructions or solving riddle from spot description.</small>
      </a>
      <a className="btn btn-outline-danger btn-lg btn-block" href={Routes.SUBMIT_CHALLENGE_SPOT_ROUTE}>
        <img className="title-icon" alt="Challenge Spot" title="Challenge Spot" src="img/icon-challenge.png" /> Challenge spot
        <br/><small>In order to complete the challenge - some specific selfie should be taken. Details explained in task description.</small>
      </a>
    </p>
  </div>
</PageWithNavbar>


export default HowToSubmitSpot;
