import $ from 'jquery';
import { DEFAULT_COORDS } from '../constants';
import { GeoPoint } from '../types';

const ipLookUp =  () => new Promise((resolve, reject) => {
    $.getJSON('https://geolocation-db.com/json/')
        .then(
            response => {
                const coords = {
                    latitude: response.latitude,
                    longitude: response.longitude
                };
                sessionStorage.setItem('homelocation', JSON.stringify(coords))
                resolve(coords);
            },
            reject
        );
});

const getLocationFromCache = () => {
    if (sessionStorage.getItem('homelocation') === null) {
        return null;
    } else {
        return JSON.parse(sessionStorage.getItem('homelocation'));
    }
}

const getFromCacheOrIp = () => new Promise((resolve, reject) => {
    const coords = getLocationFromCache();
    if (coords !== null) {
        resolve(coords);
    } else {
        ipLookUp().then(resolve, reject);
    }
});

export const getLocation = (): Promise<GeoPoint> => new Promise((resolve, reject) => {
    getFromCacheOrIp().then(resolve, () => resolve(DEFAULT_COORDS));

    // Don't want to annoy users with permission request by now
    // if ("geolocation" in navigator) {
    //     navigator.geolocation.getCurrentPosition(
    //         position => resolve(position.coords), 
    //         () => getFromCacheOrIp().then(resolve, reject)
    //     );
    // }
});