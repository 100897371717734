export default class LeafletHash {
    lastHash: string = '';

    updateMapHash(map) {
        let center = map.getCenter(),
            zoom = map.getZoom(),
            precision = Math.max(0, Math.ceil(Math.log(zoom) / Math.LN2));

        const hash = "#" + [zoom,
            center.lat.toFixed(precision),
            center.lng.toFixed(precision)
        ].join("/");

        if (this.lastHash !== hash) {
            window.location.replace(hash);
            this.lastHash = hash;
        }
    }

    parseHash() {
        let hash = window.location.hash;
        if(hash.indexOf('#') === 0) {
            hash = hash.substr(1);
        }
        var args = hash.split("/");
        if (args.length !== 3) {
            return false;
        }

        let zoom = parseInt(args[0], 10),
            lat = parseFloat(args[1]),
            lng = parseFloat(args[2]);
        if (isNaN(zoom) || isNaN(lat) || isNaN(lng)) {
            return false;
        } else {
            return {
            center: {lat, lng},
            zoom: zoom
            };
        }
    };
}