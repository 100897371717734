import React, { useEffect, useRef, useState } from 'react';
import './index.css';

import PageWithNavbar from '../../base/components/PageWithNavbar';
import PageErrorFetchingData from '../../base/components/PageErrorFetchingData';
import SpotListItem from '../../base/components/SpotListItem';

import { useLazyQuery } from '@apollo/client';
import { SEARCH_SPOTS_BY_TAGS, SEARCH_SPOTS_BY_TITLE } from '../../base/queries';
import { useParams } from 'react-router-dom';
import { Spot } from '../../base/types';
import Spinner from '../../base/components/Spinner';
import { logAnalytics } from '../../base/services/analyticsService';

let getSanitizedQueryOrHash = query => {
  let queryParam = query ? query.trim().replace(/[^a-zA-Z0-9.-]/g, '') : '';
  if (window.location.hash && queryParam === '') {
    queryParam = window.location.hash.trim().replace(/[^a-zA-Z0-9#.-]/g, '');
  }
  return queryParam;
}

const Search = () => {
  let { query } = useParams<{query: string}>();
  let queryParam = getSanitizedQueryOrHash(query);

  const searchElement = useRef(null);
  let [ searchInput, setSearchInput ] = useState<string>(queryParam || '');

  const [searchSpots, {data, loading, error, fetchMore}] = useLazyQuery(
    searchInput.startsWith('#') ? SEARCH_SPOTS_BY_TAGS : SEARCH_SPOTS_BY_TITLE,
    {fetchPolicy: "no-cache"}
  );
  
  useEffect(() => {
    if (searchInput !== '') {
      let variables = searchInput.startsWith('#') ? {tag: searchInput.replace('#', '')} : {term: searchInput};
      logAnalytics('search', {term: searchInput});
      searchSpots({
        variables: variables
      });
    }
  }, [searchInput, searchSpots]);

  let onFormSubmit = event => {
    event.preventDefault();
    let sanitizedInput = searchElement.current.value.trim().replace(/[^a-zA-Z0-9#.-\s]/g, '');
    setSearchInput(sanitizedInput);
  };

  if (loading) return <PageWithNavbar><Spinner /></PageWithNavbar>;
  if (error) return <PageErrorFetchingData error={error} />;

  return <PageWithNavbar>
    <div className="container narrow-container py-4">
      {/* Search */}
      <div className="my-3">
        <div className="card border shadow-sm">
          <div className="card-body">
            <form onSubmit={(event) => onFormSubmit(event)}>
              <input className="search form-control-lg border w-100" ref={searchElement} placeholder="Search..." type="search" defaultValue={searchInput} aria-label="Search" />
            </form>
          </div>
        </div>
      </div>

      {/* Results */}
      { data && <section className="border px-3 pt-3 mb-3 bg-white rounded shadow-sm">
        <h5 className="pb-2 mb-0">Results</h5>
        
        { data.spots.count === 0 && <>
              <hr className="m-0 p-0"/>
              <div className="w-100 text-center p-4">
                  <p className="text-muted">No matches are found</p>
              </div>
            </>
        }

        {data.spots.edges.map((spot: {node: Spot}) => {
          return (<> 
            <hr className="m-0 p-0"/>
            <SpotListItem key={spot.node.objectId} spot={spot.node} />
          </>);
        })}

        { data.spots.pageInfo.hasNextPage &&
            <div className="w-100 text-center my-3">
                <button 
                    className="btn btn-sm btn-outline-dark"
                    onClick={() => fetchMore({variables: {
                      spotsCursor: data.spots.pageInfo.endCursor
                    }})}>
                    Load more
                </button>
            </div>  
        }
      </section>
      }

    </div>
  </PageWithNavbar>;
}


export default Search;
