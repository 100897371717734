import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import { PLACE_TAGS } from '../../constants';

const selectOptions = PLACE_TAGS.map(value => {
  return {value: value, label: '#' + value};
});

type Props = {
  tags: string[],
  onTagsChange: (tags: string[]) => void
}


export default class TagSelect extends Component<Props> {
  selectedValues = [];

  constructor(props: Props) {
    super(props);
    this.selectedValues = props.tags.map(tag => { 
      return {value: tag, label: '#' + tag}
    });
  }

  render() {
    return (
      <CreatableSelect
        isMulti
        name="tags"
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={values => {
          let filteredValues = values.map(value => {
            value.value = value.value.replace(/[^a-z]+/g, '');
            value.label = '#' + value.value;
            return value;
          });
          this.selectedValues = filteredValues;
          this.props.onTagsChange(filteredValues.map(tag => tag.value))
        }}
        value={this.selectedValues}
        isOptionDisabled={(option) => this.selectedValues.length >= 3}
        options={selectOptions}
      />
    );
  }
}