import { gql } from '@apollo/client';

export const GET_TOP_SPOTS = gql`
    query GetTopSpots(
      $latitude: Float!,
      $longitude: Float!,
      $userId: ID!
    ) {
      user(id: $userId) {
        visitedCount,
        publishedCount
      }

      topVisits: users(
        where: {
          objectId: {
            notIn: [ "0FpghybwHG" ]
          }
        }
        order: visitedCount_DESC, 
        first: 5
      ) {
        edges {
          node {
            objectId
            displayName
            visitedCount
            image {
              url
            }
          }
        }
      }

      topAuthors: users(
        where: {
          objectId: {
            notIn: [ "0FpghybwHG", "rofaz7apz2" ]
          }
        }
        order: publishedCount_DESC, 
        first: 5
      ) {
        edges {
          node {
            objectId
            displayName
            publishedCount
            image {
              url
            }
          }
        }
      }

      recentSpots: spots(
        where: {
          location: {
            nearSphere:{
              latitude: $latitude, 
              longitude: $longitude
            }, 
            maxDistanceInKilometers: 50
          },
          status:  {
            equalTo: "published"
          }
        },
        order: createdAt_DESC, 
        first: 5
      ) {
        edges {
          node {
            objectId
            title
            description
            image {
                url
            }
            thumbnail {
                url
            }
            location {
                latitude
                longitude
            }
            checkins_count
            likes_count
          }
        }
      }

      popularSpots: spots(
        where: {
          location: {
            nearSphere: {
              latitude: $latitude, 
              longitude: $longitude
            }, 
            maxDistanceInKilometers: 50
          },
          status:  {
            equalTo: "published"
          }
        }
        order:likes_count_DESC,
        first: 5
      ) {
        edges {
          node {
            objectId
            title
            description
            image {
                url
            }
            thumbnail {
                url
            }
            location {
                latitude
                longitude
            }
            checkins_count
            likes_count
          }
        }
      }

      popularSpotsWorld: spots(
        where: {
          status:  {
            equalTo: "published"
          }
        }
        order:likes_count_DESC,
        first: 5
      ) {
        edges {
          node {
            objectId
            title
            description
            image {
                url
            }
            thumbnail {
                url
            }
            location {
                latitude
                longitude
            }
            checkins_count
            likes_count
          }
        }
      }
    }
`;

export const GET_NEAR_SPOTS = gql`
    query GetNearSpots( 
        $userId: ID!,
        $types: [String],
        $bottomLeftLatitude: Float!,
        $bottomLeftLongitude: Float!,
        $upperRightLatitude: Float!,
        $upperRightLongitude: Float!
    ) {
        spots(
            first: 1000,
            where: {
                location: {
                    within: {
                        box:{
                            bottomLeft: {
                                latitude: $bottomLeftLatitude,
                                longitude: $bottomLeftLongitude
                            },
                            upperRight:{
                                latitude: $upperRightLatitude,
                                longitude: $upperRightLongitude
                            }
                        }
                    }
                }
                type: {
                    in: $types
                },
                status:  {
                  equalTo: "published"
                }
            }
        ) {
            edges {
                node {
                    id
                    objectId
                    title
                    type
                    image {
                        url
                    }
                    thumbnail {
                        url
                    }
                    
                    icon {
                        url
                    }
                    location {
                        latitude
                        longitude
                    }
                    tags {
                      ... on Element {
                        value
                      }
                    }
                    checkins_count
                    likes_count
                }
            }
        }
        checkIns(
            where: {
                author:{
                    have:{
                        id: {
                            equalTo: $userId
                        }
                    }
                }
                spot: {
                    have: {
                        location: {
                            within: {
                                box:{
                                    bottomLeft: {
                                        latitude: $bottomLeftLatitude,
                                        longitude: $bottomLeftLongitude
                                    },
                                    upperRight:{
                                        latitude: $upperRightLatitude,
                                        longitude: $upperRightLongitude
                                    }
                                }
                            }
                        }
                    }
                }
            }
        ) {
            edges {
                node {
                    spot {
                      id
                      objectId
                    }
                }
            }
        }
        user(id: $userId) {
          favoriteSpots(
            where: {
              location: {
                  within: {
                      box:{
                          bottomLeft: {
                              latitude: $bottomLeftLatitude,
                              longitude: $bottomLeftLongitude
                          },
                          upperRight:{
                              latitude: $upperRightLatitude,
                              longitude: $upperRightLongitude
                          }
                      }
                  }
              }
            }
          ) {
            edges {
              node {
                objectId
              }
            }
          }
        }
    }
`;

export const GET_SINGLE_SPOT = gql`
  query GetSpot($spotId: ID!) {
    spot(id: $spotId) {
      id
      objectId
      title
      description
      status
      type
      useful_link
      createdAt
      task
      image {
        url
      }
      thumbnail {
        url
      }
      radius
      location {
        latitude
        longitude
      }
      tags {
        ... on Element {
          value
        }
      }
      author {
        objectId
        displayName
      }
      checkins_count
      likes_count
    }
  }
`;

export const GET_SPOT_CHECK_INS = gql`
  query GetSpotCheckIns( $spotId: ID, $limit: Int, $cursor: String) {
    checkIns(
      first: $limit, 
      after: $cursor, 
      where: { 
        spot: { 
          have: { 
            objectId: { 
              equalTo: $spotId 
            } 
          } 
        } 
      },
      order: createdAt_DESC
    ) {
      count
      edges {
        node {
          objectId
          createdAt
          image {
            url
          }
          thumbnail {
            url
          }
          comment
          liked
          author {
            objectId
            displayName
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_OWN_SPOTS = gql`
  query GetOwnSpots($userId: ID, $limit: Int, $spotsCursor: String, $checkInsCursor: String) {
    createdSpots: spots (
      first: $limit, 
      after: $spotsCursor,
      where:{
        author: {have: {objectId: {equalTo: $userId}}},
        status:  {
          equalTo: "published"
        }
      },
      order: createdAt_DESC
    ) {
      count
      edges {
        node {
          objectId
          title
          type
          image {
            url
          }
          thumbnail {
            url
          }
          tags {
            ... on Element {
              value
            }
          }
          likes_count
          checkins_count
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }

    checkIns(
      first: $limit, 
      after: $checkInsCursor,
      where: {
        author: {have: {objectId: {equalTo: $userId}}}
      },
      order: createdAt_DESC
    ) {
      count
      edges {
        node {
          objectId
          createdAt
          image {
            url
          }
          thumbnail {
            url
          }
          comment
          liked
          author {
            objectId
            displayName
          }
          spot {
            objectId
            title
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_PUBLIC_PROFILE = gql`
  query GetPublicProfile($userId: ID!, $limit: Int, $spotsCursor: String, $checkInsCursor: String) {
    user(id: $userId) {
      id
      displayName
      image {
        url
      }
    }
    
    createdSpots: spots (
      first: $limit, 
      after: $spotsCursor,
      where:{
        author: {have: {objectId: {equalTo: $userId}}},
        status:  {
          equalTo: "published"
        }
      },
      order: createdAt_DESC
    ) {
      count
      edges {
        node {
          objectId
          title
          type
          image {
            url
          }
          thumbnail {
            url
          }
          tags {
            ... on Element {
              value
            }
          }
          likes_count
          checkins_count
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }

    checkIns(
      first: $limit, 
      after: $checkInsCursor,
      where: {
        author: {have: {objectId: {equalTo: $userId}}}
      },
      order: createdAt_DESC
    ) {
      count
      edges {
        node {
          objectId
          createdAt
          image {
            url
          }
          thumbnail {
            url
          }
          comment
          liked
          author {
            objectId
            displayName
          }
          spot {
            objectId
            title
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_SPOTS_BY_TITLE = gql`
  query SearchSpots($term: String!) {
    spots(where: 
        {
          title: {
            text: {
              search: {
                term: $term, 
                caseSensitive: false
              }
            }
          },
          status:  {
            equalTo: "published"
          }
        }
    ) {
      count
      edges {
        node {
          objectId
          title
          type
          image {
            url
          }
          thumbnail {
            url
          }
          tags {
            ... on Element {
              value
            }
          }
          likes_count
          checkins_count
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_SPOTS_BY_TAGS = gql`
  query SearchSpots($tag: [Any]) {
    spots(where: 
        {
          tags: {
            contains: $tag
          },
          status:  {
            equalTo: "published"
          }
        }
    ) {
      count
      edges {
        node {
          objectId
          title
          type
          image {
            url
          }
          thumbnail {
            url
          }
          tags {
            ... on Element {
              value
            }
          }
          likes_count
          checkins_count
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_FAVORITE_SPOTS = gql`
query SearchFavoriteSpots($userId: ID!, $limit: Int, $cursor: String) {
  user(id: $userId) {
    favoriteSpots(
      first: $limit, 
      after: $cursor
    ) {
      count
      edges {
        node {
          objectId
          title
          type
          image {
            url
          }
          thumbnail {
            url
          }
          tags {
            ... on Element {
              value
            }
          }
          likes_count
          checkins_count
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
`;