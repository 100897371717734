import React from 'react';
import Parse from 'parse';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import './index.css';

import PageWithNavbar from '../../base/components/PageWithNavbar';
import PageErrorFetchingData from '../../base/components/PageErrorFetchingData';
import SpotListItem from '../../base/components/SpotListItem';
import CheckInCard from './components/CheckInCard';
import Spinner from '../../base/components/Spinner';


import { GET_PUBLIC_PROFILE } from '../../base/queries';
import { CheckIn, Spot } from '../../base/types';

const Profile = () => {

  let { id } = useParams<{id: string}>();

  const { data, loading, error, fetchMore } = useQuery(GET_PUBLIC_PROFILE, {
    variables: {
      userId: id,
      limit: 5
    }
  });
  if (loading) return <PageWithNavbar><Spinner /></PageWithNavbar>;
  if (error) return <PageErrorFetchingData error={error} />;
  
  return <PageWithNavbar>
    <div className="container narrow-container py-4">
      {/* Profile card */}
      <div className="card rounded p-3 mb-4">
        <div className="row no-gutters justify-content-center">
          <div>
            <img src={data.user.image?.url ?? '/img/profile.png'} className="profile-img card-img rounded-circle border" alt={`${data.user.displayName} profile`} />
          </div>
          <div className="align-self-center">
            <div className="card-body">
              <h5 className="card-title mb-0">{data.user.displayName}</h5>
              <p className="card-text font-weight-lighter">
                <span className="pr-3"><b>{data.checkIns.count}</b> check in(s) </span>
                <span className="pr-3"><b>{data.createdSpots.count}</b> spot(s) added</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Added spots */}
      <section className="added-spots border px-3 pt-3 mb-3 bg-white rounded shadow-sm">
        <h5 className="pb-2 mb-0">Added</h5>
        <hr className="m-0 p-0"/>
        {data.createdSpots.edges.map((spot: {node: Spot}, index: number) => {
          return (<> 
            { index !== 0 && <hr className="m-0 p-0"/> }
            <SpotListItem key={spot.node.objectId} spot={spot.node} />
          </>);
        })}

        { data.createdSpots.count === 0 && 
          <div className="w-100 text-center p-4">
              <p className="text-muted">No spots added yet</p>
          </div>
        }

        { data.createdSpots.pageInfo.hasNextPage &&
          <>
            <hr className="m-0 p-0"/>
            <div className="w-100 text-center my-3">
                <button 
                    className="btn btn-sm btn-outline-dark"
                    onClick={() => fetchMore({variables: {
                      spotsCursor: data.createdSpots.pageInfo.endCursor
                    }})}>
                    Load more
                </button>
            </div>
          </>
        }
      </section>


      {/* Added spots */}
      <section className="added-spots border px-3 pt-3 mb-3 bg-white rounded shadow-sm">
        <h5 className="pb-2 mb-0">Check-ins</h5>
        <hr className="m-0 p-0"/>
        {data.checkIns.edges.map((checkIn: {node: CheckIn}, index: number) => {
          return <>
            { index !== 0 && <hr className="m-0 p-0"/> }
            <CheckInCard key={checkIn.node.objectId} checkIn={checkIn.node} />
          </>;
        })}

        { data.checkIns.count === 0 &&
          <div className="w-100 text-center p-4">
              <p className="text-muted">No check-ins yet</p>
          </div>
        }

        { data.checkIns.pageInfo.hasNextPage && 
          <>
            <hr className="m-0 p-0"/>
            <div className="w-100 text-center my-3">
                <button 
                    className="btn btn-sm btn-outline-dark"
                    onClick={() => fetchMore({variables: {
                      checkInsCursor: data.checkIns.pageInfo.endCursor
                    }})}>
                    Load more
                </button>
            </div>
          </>
        }
      </section>
    </div>
  </PageWithNavbar>;
}


export default Profile;
