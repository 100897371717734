import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { useQuery } from '@apollo/client';

import PageWithNavbar from '../../base/components/PageWithNavbar';
import PageErrorFetchingData from '../../base/components/PageErrorFetchingData';
import { getLocation } from '../../base/services/locationService';
import { GET_TOP_SPOTS } from '../../base/queries';
import * as Routes from '../../base/routes';

import './index.css';
import RowCard from './components/RowCard';
import Spinner from '../../base/components/Spinner';
import { GeoPoint, Spot } from '../../base/types';
import { DEFAULT_COORDS } from '../../base/constants';

type User = {
  objectId: string
  displayName: string
  visitedCount: number
  publishedCount: number
  image: {
    url: string
  }
}

let getUserImage = (image: {url?: string}) => image?.url.replace('graphq', 'parse') ?? '/img/placeholder.jpg';

const Home = () => {
  let [coords, setCoords] = useState<GeoPoint>(DEFAULT_COORDS)

  useEffect(() => {
    getLocation().then(coords => setCoords(coords));
  }, [setCoords])

  const { loading, error, data } = useQuery(GET_TOP_SPOTS, {
    variables: {
      userId: Parse.User.current().id,
      limit: 7,
      latitude: coords.latitude,
      longitude: coords.longitude
    },
    fetchPolicy: "no-cache"
  });

  if (loading) return <PageWithNavbar><Spinner /></PageWithNavbar>;
  if (error) return <PageErrorFetchingData error={error} />;

  return <PageWithNavbar>
    <div className="container">
      <div className="rounded bg-white border row no-gutters p-2 m-0 my-2">
        <div className="col-6 col-lg-3 text-center font-weight-lighter">
          <span><b>{data.user.visitedCount}</b><br/>check in(s) </span>
        </div>
        <div className="col-6 col-lg-3 text-center font-weight-lighter">
          <span><b>{data.user.publishedCount}</b><br/>spot(s) added</span>
        </div>
        <div className="col text-center mt-3 mt-lg-0 mx-1">
          <a className="btn btn-outline-secondary m-1 btn-block" href={Routes.MAP_ROUTE}>🗺️ View map</a>
        </div>
        <div className="col text-center mt-3 mt-lg-0 mx-1">
          <a className="btn btn-outline-secondary m-1 btn-block" href={Routes.HOW_TO_SUBMIT_SPOT}>➕ Add spot</a>
        </div>
      </div>

      <div className="rounded bg-white border row p-4 m-0 justify-content-around">
        <div className="leaderboard list-group col-lg-5">
          <h5 className="mb-2">Top visitors 🤳</h5>
          {data.topVisits.edges.map((user: { node: User }) => 
            <a href={Routes.PROFILE_ROUTE.replace(':id', user.node.objectId)} className="list-group-item list-group-item-action border-0 d-flex py-1 px-0">
              <img src={getUserImage(user.node.image) } alt="profile" width="32" height="32" className="rounded-circle flex-shrink-0" />
              <div className="d-flex ml-3 w-100 justify-content-between">
                <span className="text-truncate">{ user.node.objectId === Parse.User.current().id ? <b>{user.node.displayName}</b>: user.node.displayName}</span>
                <span className="text-muted text-nowrap">{user.node.visitedCount}</span>
              </div>
            </a>
          )}
        </div>
        <div className="leaderboard list-group mt-3 mt-lg-0 col-lg-5">
          <h5 className="mb-2">Top authors 📝</h5>
          {data.topAuthors.edges.map((user: { node: User }) => 
            <a href={Routes.PROFILE_ROUTE.replace(':id', user.node.objectId)} className="list-group-item list-group-item-action border-0 d-flex py-1 px-0">
              <img src={getUserImage(user.node.image) } alt="profile" width="32" height="32" className="rounded-circle flex-shrink-0" />
              <div className="d-flex ml-3 w-100 justify-content-between">
                <span className="text-truncate">{ user.node.objectId === Parse.User.current().id ? <b>{user.node.displayName}</b>: user.node.displayName}</span>
                <span className="text-muted text-nowrap">{user.node.publishedCount}</span>
              </div>
            </a>
          )}
        </div>
      </div>

      <div className="rounded dashboard bg-white border p-4 mt-2">
        <h5>Recently added near</h5>
        { data.recentSpots.edges.length > 0 
          ? <div className="card-row mb-4">
              {data.recentSpots.edges.map((spot: { node: Spot}) => <RowCard key={spot.node.objectId} {...spot.node}/>)}
            </div>
          : <p className="text-center border-0 rounded p-4 my-4">No recently added spots found in 50km radius. Use <a href={Routes.MAP_ROUTE}>map</a> to check other areas.</p>
        }

        { data.popularSpots.edges.length > 0 &&
          <>
            <h5>Most popular near</h5>
            <div className="card-row mb-4">
              {data.popularSpots.edges.map((spot: { node: Spot; }) => 
                <RowCard key={spot.node.objectId} {...spot.node} />)
              }
            </div>
          </>
        }

        { data.popularSpots.edges.length === 0 &&
          <>
            <h5>Most popular worldwide</h5>
            <div className="card-row mb-4">
              {data.popularSpotsWorld.edges.map((spot: { node: Spot; }) => <RowCard key={spot.node.objectId} {...spot.node} />)}
            </div>
          </>
        }
      </div>
    </div>
  </PageWithNavbar>
}

export default Home;
