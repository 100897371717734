import React from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Swal from 'sweetalert2';

type Props= {
  onCroppedImageReady: (image: string) => void,
  onFileSelect?: (file: any) => void
  isRequiredInput?: boolean
  minAspectRatio?: number
  maxAspectRatio?: number
  minImageHeight?: number
  minImageWidth?: number
}

let minAspectRatio = 8/9;
let maxAspectRatio = 4/3;

let minImageWidth = 1200;
let minImageHeight = 900;

class ImageUploadCropper extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    minAspectRatio = props.minAspectRatio ?? minAspectRatio;
    maxAspectRatio = props.maxAspectRatio ?? maxAspectRatio;
    minImageWidth = props.minImageWidth ?? minImageWidth;
    minImageHeight = props.minImageHeight ?? minImageHeight;
  }

  state = {
    image: null,
    cropper: null
  }

  onSelectFile = (e) => {
    e.preventDefault();
    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else if (e.target) {
      file = e.target.files[0];
    }

    if (this.props.onFileSelect) {
      this.props.onFileSelect(file);
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({image: reader.result as any});
      this.createImage(reader.result).then(img => {
        // validate min dimensions
        if (img.width < minImageWidth || img.height < minImageHeight) {
          e.target.value = null;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Image should be at least ${minImageWidth}px wide and ${minImageHeight}px high. Provided image is only ${img.width}px wide and ${img.height}px high.`
          });
          this.setState({ image: null});
        } else {
          this.setState({image: reader.result as any});
        }
      });
    };
    reader.readAsDataURL(file);
  }

  createImage = url => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', error => reject(error))
      image.src = url
    })
  }  
  
  onCropReady = () => {
    let croppedCanvas = this.state.cropper.getCroppedCanvas({
      width: 160,
      height: 90,
      minWidth: minImageWidth,
      minHeight: minImageHeight,
      // maxWidth: 3000,  // this was making image smaller than should be
      // maxHeight: 2250,
      fillColor: '#fff',
      imageSmoothingEnabled: false,
      imageSmoothingQuality: 'high',
    }).toDataURL('image/jpeg');
    this.props.onCroppedImageReady(croppedCanvas);
  }


  checkCropAspectRatio = () => {
    let cropBoxData = this.state.cropper.getCropBoxData();
    let canvasData = this.state.cropper.getCanvasData();

    let aspectRatio = cropBoxData.width / cropBoxData.height;
    let newWidth = cropBoxData.width;
    let newHeight = cropBoxData.height;

    // try to resize width first
    if (aspectRatio < minAspectRatio) {
      newWidth = cropBoxData.height * minAspectRatio;
    } else if (aspectRatio > maxAspectRatio) {
      newWidth = cropBoxData.height * maxAspectRatio;
    }

    // check canvas boundaries
    newWidth = newWidth > canvasData.width ? canvasData.width : newWidth;

    // try to resize height
    let newAspectRatio = newWidth / cropBoxData.height;
    if (newAspectRatio < minAspectRatio) {
      newHeight = newWidth / minAspectRatio;
    } else if (newAspectRatio > maxAspectRatio) {
      newHeight = newWidth / maxAspectRatio;
    }

    this.state.cropper.setCropBoxData({width: newWidth, height: newHeight});
  }

  checkMinimalWidthHeight = (event) => {
    let data = this.state.cropper.getData();

    if (data.width < minImageWidth) {
        data.width = minImageWidth;
        this.state.cropper.setData(data);
    }

    if (data.height < minImageHeight) {
        data.height = minImageHeight;
        this.state.cropper.setData(data);
    }
  }

  async resizeCropToCanvas() {
    var canvasData = this.state.cropper.getCanvasData();
    await this.state.cropper.setCropBoxData({ height: canvasData.height, width: canvasData.width  })
  }

  render() {
    return (
      <div className="row bg-light rounded border p-2 text-center no-gutters">
        <div className="mb-2 col-12">
          <input type="file" accept="image/jpeg" className="choose-file border" onChange={this.onSelectFile} required={this.props.isRequiredInput ?? true} />
          <div className="text-muted">Image should be at least {minImageWidth}px wide and {minImageHeight}px high.</div>
        </div>
        <div className="col-lg-12">
            {this.state.image &&
              <div className="w-100" style={{height: 400}}>
                <Cropper
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={4 / 3}
                    src={this.state.image}
                    viewMode={1}
                    background={false}
                    checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                      this.setState({'cropper': instance});
                    }}
                    guides={true}
                    cropmove = {(event) => {
                      this.checkCropAspectRatio();
                      this.checkMinimalWidthHeight(event);
                    }}
                    ready={async() => {
                      await this.resizeCropToCanvas();   // make full-width container
                      this.checkCropAspectRatio();  // resize to allowed
                      this.onCropReady(); // make initial crop
                    }}
                    cropend={this.onCropReady}
                  />
              </div>
            }
        </div>
      </div>
    )
  }
}

export default ImageUploadCropper;