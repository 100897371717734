import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { logAnalytics } from '../services/analyticsService';

import PrivateRoute from './PrivateRoute';
import * as Routes from '../routes';
import Home from '../../page/Home';
import Landing from '../../page/Landing';
import HowToPlay from '../../page/HowToPlay';
import MapBrowser from '../../page/MapBrowser';
import SpotInfo from '../../page/SpotInfo';
import SubmitCheckIn from '../../page/SubmitCheckIn';
import SubmitSpot from '../../page/SubmitSpot';
import MyProfile from '../../page/MyProfile';
import Profile from '../../page/Profile';
import Search from '../../page/Search';
import HowToSubmitSpot from '../../page/HowToSubmitSpot';
import SubmitChallengeSpot from '../../page/SubmitChallengeSpot';
import FavoriteSpots from '../../page/FavoriteSpots';
import SubmitHuntSpot from '../../page/SubmitHuntSpot';
import EditProfile from '../../page/EditProfile';

function Router() {
  let location = useLocation();
  React.useEffect(() => {
    logAnalytics('pageView', {page: location.pathname.split('/')[1], pathname: location.pathname});
  }, [location]);

  return (
    <Switch>
      <Route exact push={true} path={Routes.LANDING_ROUTE} component={Landing}/>
      
      {/* Exposed to crawler to attract traffic */}
      <Route path={Routes.SINGLE_SPOT_ROUTE} component={SpotInfo}/>

      <PrivateRoute path={Routes.HOME_ROUTE} component={Home}/>
      <PrivateRoute path={Routes.MAP_ROUTE} component={MapBrowser}/>
      <PrivateRoute path={Routes.HOW_TO_PLAY_ROUTE} component={HowToPlay}/>
      <PrivateRoute path={Routes.HOW_TO_SUBMIT_SPOT} component={HowToSubmitSpot}/>

      <PrivateRoute path={Routes.SUBMIT_REGULAR_SPOT_ROUTE} component={SubmitSpot}/>
      <PrivateRoute path={Routes.SUBMIT_CHALLENGE_SPOT_ROUTE} component={SubmitChallengeSpot}/>
      <PrivateRoute path={Routes.SUBMIT_HUNT_SPOT_ROUTE} component={SubmitHuntSpot}/>

      <PrivateRoute path={Routes.CHECK_IN_ROUTE} component={SubmitCheckIn}/>

      <PrivateRoute path={Routes.MY_PROFILE_ROUTE} component={MyProfile}/>
      <PrivateRoute path={Routes.PROFILE_ROUTE} component={Profile}/>
      <PrivateRoute path={Routes.EDIT_PROFILE} component={EditProfile}/>

      <PrivateRoute path={Routes.SEARCH_ROUTE} component={Search}/>
      <PrivateRoute path={Routes.FAVORITE_SPOTS_ROUTE} component={FavoriteSpots}/>
    </Switch>
  );
}

export default Router;
