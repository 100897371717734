import React, { useMemo, useState } from 'react';
import Parse from 'parse';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import './index.css';
import PageWithNavbar from '../../base/components/PageWithNavbar';

import { MY_PROFILE_ROUTE } from '../../base/routes';
import { slugify } from '../../base/helpers';
import Spinner from '../../base/components/Spinner';
import { logAnalytics, logError } from '../../base/services/analyticsService';
import ImageUploadCropper from '../../base/components/ImageUploadCropper';

const EditProfile = () => {
  const history = useHistory();

  const [user, setUser] = useState<Parse.User>(null);
  const [displayName, setDisplayName] = useState<string>('');
  const [croppedImageBase64, setCroppedImageBase64] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useMemo(() => {
    Parse.User.current().fetch().then((user) => {
      console.log('fetch');
      setUser(user);
      setDisplayName(user.get('displayName'));
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <PageWithNavbar><Spinner /></PageWithNavbar>;

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (croppedImageBase64) {
      let parseFile = new Parse.File(slugify('profile') + '.png', { base64: croppedImageBase64.split('base64,')[1] });
      await parseFile.save();
      // await user.get('image').destroy();
      user.set('image', parseFile);
    }
    user.set('displayName', displayName);

    try{
      await user.save();
      Swal.fire({
        icon: 'success',
        title: 'Profile info updated'
      });
      logAnalytics('profileEdited', {user: user.get('email')});
    } catch(err) {
      if (err.message) {
        Swal.fire({icon: 'error', text: err.message});
      }
      logError(err);
    } finally {
      history.push(MY_PROFILE_ROUTE);
      setIsLoading(false);
    }
  }

  if (isLoading) return <Spinner />;

  return <PageWithNavbar>
    <div className="container narrow-container">

      <div className="px-3 py-3 bg-white border rounded shadow-sm">
        <h4 className="mb-3">Edit profile</h4>
        <form className="needs-validation" onSubmit={e => onSubmit(e)}>
          <div className="mb-3">
            <label htmlFor="display_name">Display name</label>
            <input type="text" className="form-control" id="display_name" placeholder="" minLength={5} maxLength={45} defaultValue={displayName} onChange={e => {setDisplayName(e.target.value)}} required />
          </div>

          <label>Profile image</label>
          <ImageUploadCropper 
            isRequiredInput={false} 
            onCroppedImageReady={image => setCroppedImageBase64(image)} 
            minAspectRatio={1}
            maxAspectRatio={1}
            minImageWidth={640}
            minImageHeight={640}/>

          <hr className="mb-4" />

          <button className="btn btn-warning btn-lg btn-block mt-2" type="submit">Submit</button>
        </form>
      </div>
    </div>
  </PageWithNavbar>
}

export default EditProfile;
