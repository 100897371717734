import React from 'react';
import Parse from 'parse';
import { useQuery } from '@apollo/client';

import './index.css';

import PageWithNavbar from '../../base/components/PageWithNavbar';
import PageErrorFetchingData from '../../base/components/PageErrorFetchingData';
import SpotListItem from '../../base/components/SpotListItem';
import Spinner from '../../base/components/Spinner';

import { SEARCH_FAVORITE_SPOTS } from '../../base/queries';
import { Spot } from '../../base/types';

function deleteSpot(spotId: string) {
  let spot = new (Parse.Object.extend('Spot'))();
  spot.id = spotId;
  Parse.User.current().relation('favoriteSpots').remove(spot);
  return Parse.User.current().save();
};

const FavoriteSpots = () => {

  const { data, loading, error, fetchMore } = useQuery(SEARCH_FAVORITE_SPOTS, {
    variables: {
      userId: Parse.User.current().id,
      limit: 5
    }
  });
  if (loading) return <PageWithNavbar><Spinner /></PageWithNavbar>;
  if (error) return <PageErrorFetchingData error={error} />;

  return <PageWithNavbar>
    <div className="container narrow-container py-4">
      {/* Favorite spots */}
      <section className="added-spots border px-3 pt-3 mb-3 bg-white rounded shadow-sm">
        <h5 className="pb-2 mb-0">Favorite spots</h5>
        <hr className="m-0 p-0"/>
        {data.user.favoriteSpots.edges.map((spot: {node: Spot}, index: number) => {
          return (<div key={spot.node.objectId}> 
            { index !== 0 && <hr className="m-0 p-0"/> }
            <SpotListItem spot={spot.node} deleteBtnHandler={(spotId) => deleteSpot(spotId)} />
          </div>);
        })}

        { data.user.favoriteSpots.count === 0 && 
          <div className="w-100 text-center p-4">
              <p className="text-muted">No spots added yet</p>
          </div>
        }

        { data.user.favoriteSpots.pageInfo.hasNextPage &&
          <>
            <hr className="m-0 p-0"/>
            <div className="w-100 text-center my-3">
                <button 
                    className="btn btn-sm btn-outline-dark"
                    onClick={() => fetchMore({variables: {
                      cursor: data.user.favoriteSpots.pageInfo.endCursor
                    }})}>
                    Load more
                </button>
            </div>
          </>
        }
      </section>
    </div>
  </PageWithNavbar>;
}

export default FavoriteSpots;

