import React, { useState } from 'react';
import Parse from 'parse';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Swal from 'sweetalert2';

import './index.css';
import PageWithNavbar from '../../base/components/PageWithNavbar';
import PageWithError from '../../base/components/PageWithError';

import { GET_SINGLE_SPOT } from '../../base/queries';
import { Spot } from '../../base/types';
import { MAP_ROUTE, SINGLE_SPOT_ROUTE } from '../../base/routes';
import { buildMapLocationLink, slugify } from '../../base/helpers';
import SpotListItem from '../../base/components/SpotListItem';
import Spinner from '../../base/components/Spinner';
import { logAnalytics } from '../../base/services/analyticsService';
import ImageUploadCropper from '../../base/components/ImageUploadCropper';

const saveFile = (title, croppedImageBase64) => {
  var parseFile = new Parse.File(slugify(title) + '.png', { base64: croppedImageBase64.split('base64,')[1] });
  return parseFile.save();
}

const SubmitCheckIn = () => {
  const history = useHistory();

  const [comment, setComment] = useState<string>('');
  const [liked, setLiked] = useState<boolean>(false);
  const [croppedImageBase64, setCroppedImageBase64] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let { id } = useParams<{id: string}>();
  const { loading, error, data } = useQuery(GET_SINGLE_SPOT, {
    variables: {
      spotId: id
    }
  });

  if (loading) return <PageWithNavbar><Spinner /></PageWithNavbar>;
  if (error) return <PageWithError><p><h3>Error :(</h3>{JSON.stringify(error)}</p></PageWithError>;

  // if (data.spot.length !== 1) {
  //   return <PageWithError><p><h3 className="text-center">Not found :(</h3></p></PageWithError>;
  // }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);

    saveFile(`checkin-${spot.title}`, croppedImageBase64).then(imageFile => {
      const CheckIn = Parse.Object.extend('CheckIn');
      const checkIn = new CheckIn();

      checkIn.set('comment', comment);
      checkIn.set('liked', liked);
      checkIn.set('image', imageFile);

      const Spot = Parse.Object.extend('Spot');
      const spotObject = new Spot();
      spotObject.id = spot.objectId;
      checkIn.set('spot', spotObject);

      checkIn.save().then(async(newCheckIn) => {
        setIsLoading(false);
        // refetching fresh checkin count
        await Parse.User.current().fetch();
        Swal.fire({
          icon: 'success',
          html: 'Congratulations!',
          title: `<span class="title-word title-word-2">${Parse.User.current().get('visitedCount')}</span><span class="title-word title-word-3"> check-in(s)</span>`,
          footer: `<a href="${buildMapLocationLink(spot.location)}">Back to the map</a>`
        });
        logAnalytics('submitCheckIn', {spotTitle: spot.title, liked: liked ? 'true' : 'false', author: '' + Parse.User.current().id});
        history.push(SINGLE_SPOT_ROUTE.replace(':id', spot.objectId));
      }, async(error) => {
        setIsLoading(false);
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message
        })
        await logAnalytics('submitCheckInError', {message: error.message, author: '' + Parse.User.current().id});
      });
    });
  }

  const spot: Spot = data.spot;

  if (isLoading) return <Spinner />;

  return <PageWithNavbar>
    <div className="container narrow-container">
      <div className="border px-3 my-3">
        <SpotListItem spot={spot} />
      </div>

      <div className="px-3 py-3 bg-white border rounded shadow-sm">
        <h4 className="mb-3">Check In</h4>
        <form className="needs-validation" onSubmit={e => onSubmit(e)}>
          <div className="mb-3">
            <label htmlFor="comment">Comment</label>
            <textarea className="form-control" id="comment" value={comment} onChange={e => setComment(e.target.value)} minLength={1} maxLength={500} required></textarea>
            <small className="form-text text-muted">Share your visit experience</small>
          </div>

          <div className="mb-3 custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="liked-switch" checked={liked} onChange={e => setLiked(e.target.checked)} />
            <label className="custom-control-label" htmlFor="liked-switch">Like the spot?</label>
          </div>
          
          <ImageUploadCropper onCroppedImageReady={image => setCroppedImageBase64(image)} />

          <hr className="mb-3" />
          <div className="mb-3 custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="data-usage-switch" required={true} />
              <label className="custom-control-label" htmlFor="data-usage-switch">By submitting form I agree that photo belongs to me and provided data would be publically available</label>
          </div>

          <hr className="mb-4" />

          <button className="btn btn-warning btn-lg btn-block mt-2" type="submit">Submit</button>
        </form>
      </div>
    </div>
  </PageWithNavbar>
}

export default SubmitCheckIn;
