import React, { useLayoutEffect, useState } from 'react';
import Parse from 'parse';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import exifr from 'exifr/dist/lite.esm';

import PageWithNavbar from '../../base/components/PageWithNavbar';
import TagSelect from '../../base/components/Form/TagSelect';

import { formatStringCoordinate, slugify } from '../../base/helpers';
import { GeoPoint } from '../../base/types';

import './index.css';
import { SINGLE_SPOT_ROUTE } from '../../base/routes';
import Spinner from '../../base/components/Spinner';
import { logAnalytics } from '../../base/services/analyticsService';
import LocationPicker from '../../base/components/Form/LocationPicker';
import ImageUploadCropper from '../../base/components/ImageUploadCropper';
import { clearRegularForm, retrieveRegularForm, saveRegularForm } from '../../base/services/localStorageForm';

const saveFile = (title, croppedImageBase64) => {
    var parseFile = new Parse.File(slugify(title) + '.png', { base64: croppedImageBase64.split('base64,')[1] });
    return parseFile.save();
}

const SubmitSpot = () => {
  const history = useHistory();

  let savedForm = retrieveRegularForm();
  const [title, setTitle] = useState<string>(savedForm.title || '');
  const [description, setDescription] = useState<string>(savedForm.description || '');
  const [tags, setTags] = useState<string[]>(savedForm.tags || []);
  const [usefulLink, setUsefulLink] = useState<string>(savedForm.usefulLink || '');
  const [croppedImageBase64, setCroppedImageBase64] = useState<string>('');
  const [location, setLocation] = useState<{latitude: string, longitude: string}>(savedForm.location || {latitude: '56.948889', longitude: '24.106389'});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // just a proxy
  const setNumberLocation = (latitude: number, longitude: number) => setLocation(
    {
      latitude: formatStringCoordinate(latitude.toString()), 
      longitude: formatStringCoordinate(longitude.toString())
    }
  );

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    saveFile(title, croppedImageBase64).then(imageFile => {
      const Spot = Parse.Object.extend('Spot');
      const spot = new Spot();

      spot.set('type', 'regular');
      spot.set('status', 'pending');
      
      spot.set('title', title);
      spot.set('description', description);
      spot.set('author', Parse.User.current());
      spot.set('tags', tags);
      spot.set('useful_link', usefulLink);
      spot.set('location', new Parse.GeoPoint(parseFloat(location.latitude), parseFloat(location.longitude)));
      spot.set('image', imageFile);
      
      spot.save().then(async(spot) => {
        setIsLoading(false);
        // Execute any logic that should take place after the object is saved.
        Swal.fire({
          icon: 'success',
          text: 'Spot successfully submitted for review 👍👍',
        }).then(() => history.push(SINGLE_SPOT_ROUTE.replace(':id', spot.id)))
        clearRegularForm();
        logAnalytics('submitSpot', {spotTitle: spot.get('title'), type: spot.get('type'), author: Parse.User.current().id});
      }, async(error) => {
        setIsLoading(false);
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message
        })
        await logAnalytics('submitSpotError', {error: error.message, spotTitle: spot.title, author: '' + Parse.User.current().id});
      });
    });
  }

  const onGpsFoundInImage = (geoPoint: GeoPoint) => {
    if (!geoPoint) {
      // no location data in file
      return;
    }

    if (!geoPoint.latitude || !geoPoint.longitude) {
      Swal.fire({
        title: 'Geolocation data',
        text: `Geolocation data in the uploaded file was stripped out by Android or iOS file picker for security reasons. 
        In order to use it as spot location automatically - choose different file picker on upload or use desktop PC.`
      });
      return;
    }

    Swal.fire({
      title: 'Geolocation data',
      text: 'Geolocation data found in uploaded image. Do you wan\'t to use it as spot location?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Use data',
      denyButtonText: 'Skip',
    }).then((result) => {
      if (result.isConfirmed) {
        setNumberLocation(geoPoint.latitude, geoPoint.longitude);
      }
    })
  }
  
  useLayoutEffect(() => {
    saveRegularForm(title, description, tags, usefulLink, location);
  }, [description, location, tags, title, usefulLink]);

  const onFormClear = () => {
    Swal.fire({
      title: 'Please confirm form clear',
      showCancelButton: true,
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        clearRegularForm();
        window.location.reload();
      }
    })
  }

  if (isLoading) return <Spinner />;

  return <PageWithNavbar>
    <div className="container bg-white border my-4 p-4 rounded">
      <h4 className="mb-3">Regular spot</h4>
      <div className="alert alert-info border" role="alert">
        Please provide spot details in English 🙏 SelfieHunt is international game and it's vital for everyone to be able to understand spot title and description.  
      </div>
      <form className="needs-validation" onSubmit={e => onSubmit(e)}>
        <div className="mb-3">
          <label htmlFor="title">Title</label>
          <input type="text" className="form-control" id="title" placeholder="" value={title} minLength={2} maxLength={80} onChange={e => setTitle(e.target.value)} required={true} />
          <small className="form-text text-muted">Make it precise and clear</small>
        </div>

        <div className="mb-3">
          <label htmlFor="formDescription">Description</label>
          <textarea className="form-control" id="formDescription" aria-label="With textarea" minLength={50} maxLength={1000} value={description} onChange={e => setDescription(e.target.value)} required={true}></textarea>
          <small className="form-text text-muted">
            Mention the most interesting things about spot. Why it's worth to visit? <br />
            Mention the details about spot. What is the best time to visit? How to find spot? <br />
            Use "Useful link" section to link resources with more info about spot.
          </small>
        </div>

        <div className="mb-3">
          <label htmlFor="tags">Tags <span className="text-muted">(Optional)</span></label>
          <TagSelect tags={tags} onTagsChange={tags => setTags(tags)} />
          <small className="form-text text-muted">Choose up to 3 tags related to the spot</small>
        </div>

        <div className="mb-3">
          <label htmlFor="usefulLink">Useful Link <span className="text-muted">(Optional)</span></label>
          <input type="url" pattern="https?://.+" className="form-control" id="usefulLink" value={usefulLink} maxLength={100} onChange={e => setUsefulLink(e.target.value)} />
          <small className="form-text text-muted">Provide link giving more information about place</small>
        </div>

        <hr className="mb-4" />

        <h4 className="mb-3">Image</h4>
        <p>
          <small className="text-muted">Make sure place is clearly visible.</small>
        </p>

        <ImageUploadCropper 
          onCroppedImageReady={image => setCroppedImageBase64(image) }
          onFileSelect={file => exifr.gps(file).then((geoData: GeoPoint) => onGpsFoundInImage(geoData))} />

        <hr className="mb-4" />

        <h4 className="mb-3">Location</h4>
        <p>
          <small className="text-muted">Specify exact location of the spot either by specifying coordinates or by moving marker.</small>
        </p>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="latitude">Latitude</label>
            <input type="text" className="form-control" id="latitude" placeholder="" value={location.latitude} maxLength={12} onChange={e => setLocation({...location, latitude: formatStringCoordinate(e.target.value)})} required={true} />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="longitude">Longitude</label>
            <input type="text" className="form-control" id="lastName" placeholder="" value={location.longitude} maxLength={12} onChange={e => setLocation({...location, longitude: formatStringCoordinate(e.target.value)})} required={true} />
          </div>
        </div>

        <div style={{height: 300}} className="mb-3">
          <LocationPicker 
            location={{latitude: parseFloat(location.latitude), longitude: parseFloat(location.longitude)}}
            onLocationChange={(newLocation: GeoPoint) => { setNumberLocation(newLocation.latitude, newLocation.longitude) }} />
        </div>

        <hr className="mb-3" />
        <div className="mb-3 custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="data-usage-switch" required={true} />
            <label className="custom-control-label" htmlFor="data-usage-switch">By submitting form I agree that uploaded photo belongs to me, provided data would be publically available and information about place is correct</label>
        </div>

        <hr className="mb-4" />

        <button className="btn btn-warning btn-lg btn-block" type="submit" >Submit spot</button>
        <button className="btn btn-block" onClick={onFormClear} type="button">Clear</button>
      </form>
    </div>
  </PageWithNavbar>
}

export default SubmitSpot;

