import React, { FC, ReactElement } from 'react';

type Props = {
  fbSignUp: () => {},
  googleSignUp: () => {},
}

const SignUpButtons: FC<Props> = ({ fbSignUp, googleSignUp }): ReactElement => { 
  return (
    <>
      <button className="btn fb-sign-in" onClick={(e) => {e.preventDefault(); fbSignUp()}}>
        <i className="fab fa-facebook-f pr-1"></i> Facebook
      </button>
      <button className="btn google-sign-in ml-2" onClick={(e) => {e.preventDefault(); googleSignUp()}}>
        <i className="fab fa-google pr-1"></i> Google
      </button>
    </>
  );
}

export default SignUpButtons;