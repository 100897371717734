import React from 'react';
import TagSelect from '../../../base/components/Form/TagSelect';
import { SPOT_TYPE_CHALLENGE, SPOT_TYPE_HUNT, SPOT_TYPE_REGULAR } from '../../../base/constants';
import { FavoriteFilter, VisitedFilter } from '../../../base/types';

type Props = {
    tagsFilter: string[]
    onTagsChange: (tags: string[]) => void
    typeFilter: string[]
    onTypeFilterToggle: (type: string) => void
    visitedFilter: VisitedFilter
    onVisitedFilterChange: (filter: VisitedFilter) => void
    favoriteFilter: FavoriteFilter
    onFavoriteFilterChange: (filter: FavoriteFilter) => void
}

const Sidebar = (props: Props) => {
    const getTypeActiveClass = (type: string) => props.typeFilter.includes(type) ? ' active' : '';

    return <div className="col-lg-3 mh-100 pb-3" id="sidebar" style={{ overflowY: 'auto' }}>
        <div className="row w-100 no-gutters text-muted">
            <div className="col-12 col-sm-6 col-lg-12 mt-2 px-2">
                <p className="my-2 text-uppercase">Types</p>
                <div className="m-0">
                    <button type="button" 
                            className={"mb-1 mr-1 d-lg-block btn btn-outline-warning btn-sm " + getTypeActiveClass(SPOT_TYPE_REGULAR)} 
                            onClick={() => props.onTypeFilterToggle(SPOT_TYPE_REGULAR)}>
                        <img className="btn-img" src="img/pin-spot.png" alt="Regular" /> Regular
                    </button>
                    <button type="button" 
                            className={"mb-1 mr-1 d-lg-block btn btn-outline-warning btn-sm " + getTypeActiveClass(SPOT_TYPE_HUNT)} 
                            onClick={() => props.onTypeFilterToggle(SPOT_TYPE_HUNT)}>
                        <img className="btn-img" src="img/pin-hunt.png" alt="Hunt" /> Hunt
                    </button>
                    <button type="button" 
                            className={"mb-1 mr-1 d-lg-block btn btn-outline-warning btn-sm" + getTypeActiveClass(SPOT_TYPE_CHALLENGE)} 
                            onClick={() => props.onTypeFilterToggle(SPOT_TYPE_CHALLENGE)}>
                        <img className="btn-img" src="img/pin-challenge.png" alt="Challenge" /> Challenge
                    </button>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-12 px-2 mt-2">
                <p className="my-2 text-uppercase">Tags</p>
                <TagSelect tags={props.tagsFilter} onTagsChange={tags => props.onTagsChange(tags)} />
            </div>
            <div className="col-12 col-sm-6 col-lg-12 mt-2 px-2">
                <p className="my-2 text-uppercase">Visit status</p>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label className={"btn btn-outline-warning active btn-sm"}>
                        <input type="radio" name="options" id="option1" 
                            checked={props.visitedFilter === 'all'} 
                            onClick={() => props.onVisitedFilterChange('all')}/> All
                    </label>
                    <label className={"btn btn-outline-warning btn-sm"}>
                        <input type="radio" name="options" id="option2" 
                            checked={props.visitedFilter === 'visited'} 
                            onClick={() => props.onVisitedFilterChange('visited')}/> Visited
                    </label>
                    <label className={"btn btn-outline-warning btn-sm"}>
                        <input type="radio" name="options" id="option3" 
                            checked={props.visitedFilter === 'non-visited'} 
                            onClick={() => props.onVisitedFilterChange('non-visited')}/> Not
                    </label>
                </div>
            </div>
            
            <div className="col-12 col-sm-6 col-lg-12 mt-2 px-2">
                <p className="my-2 text-uppercase">Favorites</p>
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label className={"btn btn-outline-warning active btn-sm"}>
                        <input type="radio" name="options" id="option1" 
                            checked={props.favoriteFilter === 'all'} 
                            onClick={() => props.onFavoriteFilterChange('all')}/> All
                    </label>
                    <label className={"btn btn-outline-warning btn-sm"}>
                        <input type="radio" name="options" id="option2" 
                            checked={props.favoriteFilter === 'favorite'} 
                            onClick={() => props.onFavoriteFilterChange('favorite')}/> Favorite
                    </label>
                    <label className={"btn btn-outline-warning btn-sm"}>
                        <input type="radio" name="options" id="option3" 
                            checked={props.favoriteFilter === 'non-favorite'} 
                            onClick={() => props.onFavoriteFilterChange('non-favorite')}/> Not
                    </label>
                </div>
            </div>
        </div>
    </div>
}

export default Sidebar;