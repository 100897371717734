
export const SPOT_TYPE_REGULAR = 'regular';
export const SPOT_TYPE_HUNT = 'hunt';
export const SPOT_TYPE_PROMO = 'promo';
export const SPOT_TYPE_CHALLENGE = 'challenge';

export const SPOT_TYPES = [
    SPOT_TYPE_REGULAR,
    SPOT_TYPE_HUNT,
    SPOT_TYPE_CHALLENGE,
];

export const DEFAULT_COORDS = {
    latitude: 56.948889, 
    longitude: 24.106389
};

export const DEFAULT_ZOOM = 13;

export const PLACE_TAGS = [
    'art',
    'architecture',
    'historicsite',
    'nature',
    'graffiti',
    'sculpture',
    'fountain',
    'lighthouse',
    'castle',
    'statue',
    'abandoned',
    'sign',
    'bridge',
    'mural',
    'cave',
    'history',
    'waterfall',
    'cliff',
    'clock',
    'park',
    'street',
    'tower',
    'tree',
    'monument'
];

export const MAP_PIN_TYPE_PIN = 'pin';
export const MAP_PIN_TYPE_THUMBNAIL = 'thumbnail';

export const USER_ROLE_REVIEWER = 'reviewer';