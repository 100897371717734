import React from 'react';
import Parse from 'parse';
import hello from 'hellojs';

import './index.css';

import * as Routes from '../../routes';

const Navbar = () => {
  const user = Parse.User.current();
  const parseLogOut = () => {
    Parse.User.logOut().then(() => {
      window.location.href = Routes.LANDING_ROUTE;
    });
  }
  const handleLogOut = () => {
    if (user.get('authData').hasOwnProperty('facebook')) {
      hello('facebook').logout().then(parseLogOut, console.error);
    } 
    if(user.get('authData').hasOwnProperty('google')) {
      hello('google').logout().then(parseLogOut, console.error);
    }
  };

  return (
    <nav className="navbar navbar-expand-md navbar-light p-3 px-md-4 shadow-sm bg-white bottom-border">
      <a className="navbar-brand p-0" href={Routes.HOME_ROUTE}>
        <img src="/img/selfiehunt.png" style={{height: 35}} alt=""/>
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav ml-auto mr-3">
          <li className="nav-item active">
            <a className="nav-link text-dark p-2" href={Routes.MAP_ROUTE}>Map</a>
          </li>
          <li className="nav-item">
            <a className="nav-link text-dark p-2" href={Routes.HOW_TO_SUBMIT_SPOT}>Add spot</a>
          </li>
          <li className="nav-item">
            <a className="nav-link text-dark p-2" href={Routes.HOW_TO_PLAY_ROUTE}>How to play</a>
          </li>
          <li className="nav-item active">
            <a className="nav-link text-dark p-2" href="mailto:selfiehuntapp@gmail.com?subject=Bug report">Report a bug</a>
          </li>
          <li className="nav-item active">
            <a className="nav-link text-dark p-2" href="mailto:selfiehuntapp@gmail.com?subject=Bug report">Contact</a>
          </li>
          {
            !!user 
            ? <li className="nav-item dropdown px-2">
                <a className="nav-link dropdown-toggle p-0" href="/#" id="profile-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {
                    !!user?.get('image') 
                      ? <img src={user?.get('image').url()} alt="User profile" className="navbar-profile-image rounded-circle border" />
                      : <img src="/img/profile.png" alt="User profile" className="navbar-profile-image rounded-circle border"/>
                  }
                </a>
                <div className="dropdown-menu" aria-labelledby="profile-dropdown">
                  <a className="dropdown-item" href={Routes.FAVORITE_SPOTS_ROUTE}>Favorites</a>
                  <a className="dropdown-item" href={Routes.MY_PROFILE_ROUTE}>Profile</a>
                  <a className="dropdown-item" href="/#" onClick={(event) => { event.preventDefault(); handleLogOut()}}>Log Out</a>
                </div>
              </li>
            : <li className="nav-item dropdown px-2">
                <a className="btn btn-outline-secondary" href={Routes.LANDING_ROUTE}>Sign in / Sign up</a>
              </li>
          }
        </ul>
      </div>
    </nav>
  )
};

export default Navbar;
