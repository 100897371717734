import React, { ReactChild, ReactChildren } from 'react';
import {Helmet} from "react-helmet";
import Navbar from './Navbar';

interface Props {
  children: ReactChild | ReactChildren
  pageTitle?: string
  pageDescription?: string 
  pageImageUrl?: string 
  pageAuthor?: string
}

const PageWithNavbar = (props: Props) => <div className="bg-light h-100 w-100">
  <Helmet>
      { props.pageTitle && <title>{props.pageTitle}</title>}
      { props.pageDescription && <meta name="description" content={props.pageDescription} />}
      { props.pageAuthor && <meta name="author" content={props.pageAuthor} />}
      
      { props.pageTitle && <meta property="og:title" content={props.pageTitle} />}
      { props.pageDescription && <meta property="og:description" content={props.pageDescription} />}
      { props.pageImageUrl && <meta property="og:image" content={props.pageImageUrl} />}
  </Helmet>
  <Navbar />
  {props.children}
</div>

export default PageWithNavbar;