import { useQuery } from "@apollo/client/react";
import React from "react";
import { GET_SPOT_CHECK_INS } from "../../../base/queries";
import { CheckIn } from "../../../base/types";
import CheckInCard from "./CheckInCard";

const SpotCheckIns = (props: {spotId: string, likesCount: number, checkinsCount: number}) => {

    /*
     * checkIns field managed using relayStylePagination
     * utility defined in Apollo client cache client/src/index.tsx
     */
    const { data, loading, fetchMore } = useQuery(GET_SPOT_CHECK_INS, {
        variables: {
            limit: 5,
            spotId: props.spotId
        },
      });

    if (loading) return <p>Loading...</p>;

    return <section className="check-ins mt-5">
        <h5 className="pb-2 mb-0 border-bottom">
            Check-ins<span className="metrics ml-2 float-right"><i className="fas fa-check"></i>{ props.checkinsCount }<i className="ml-2 far fa-heart"></i>{ props.likesCount }</span>
        </h5>

        {data.checkIns.edges.map((checkIn: {node: CheckIn}) => <CheckInCard key={checkIn.node.objectId} checkIn={checkIn.node} />)}

        { data.checkIns.count === 0 && 
            <div className="w-100 text-center p-4">
                <p className="text-muted">No check-ins yet. Be the first to find this gem!</p>
            </div>
        }

        { data.checkIns.pageInfo.hasNextPage &&
            <div className="w-100 text-center mt-3">
                <button 
                    className="btn btn-sm btn-outline-dark"
                    onClick={() => fetchMore({variables: {
                        cursor: data.checkIns.pageInfo.endCursor
                    }})}>
                    Load more
                </button>
            </div>  
        }
    </section>;
};

export default SpotCheckIns;