import { MapFilters, PinTypeFilter, VisitedFilter, GeoPoint, FavoriteFilter } from "../types";

export const saveRegularForm = (
    title: string, 
    description: string, 
    tags: string[], 
    usefulLink: string,
    location: {latitude: string, longitude: string}
) => {
    if (!localStorage) {
        return;
    }
    localStorage.setItem('regular_spot_form.title', title);
    localStorage.setItem('regular_spot_form.description', description);
    localStorage.setItem('regular_spot_form.tags', JSON.stringify(tags));
    localStorage.setItem('regular_spot_form.usefulLink', usefulLink);
    localStorage.setItem('regular_spot_form.location', JSON.stringify(location));
}

export const saveHuntForm = (
    title: string, 
    description: string, 
    tags: string[], 
    usefulLink: string,
    radius: number,
    location: {latitude: string, longitude: string}
) => {
    if (!localStorage) {
        return;
    }
    localStorage.setItem('hunt_spot_form.title', title);
    localStorage.setItem('hunt_spot_form.description', description);
    localStorage.setItem('hunt_spot_form.tags', JSON.stringify(tags));
    localStorage.setItem('hunt_spot_form.usefulLink', usefulLink);
    localStorage.setItem('hunt_spot_form.radius', radius.toString());
    localStorage.setItem('hunt_spot_form.location', JSON.stringify(location));
}

export const saveChallengeForm = (
    title: string, 
    description: string, 
    tags: string[], 
    usefulLink: string,
    location: {latitude: string, longitude: string},
    task: string
) => {   
    
    if (!localStorage) {
        return;
    }
    localStorage.setItem('challenge_spot_form.title', title);
    localStorage.setItem('challenge_spot_form.description', description);
    localStorage.setItem('challenge_spot_form.tags', JSON.stringify(tags));
    localStorage.setItem('challenge_spot_form.usefulLink', usefulLink);
    localStorage.setItem('challenge_spot_form.location', JSON.stringify(location));
    localStorage.setItem('challenge_spot_form.task', task);
}

export const retrieveRegularForm = () => {
    if (!localStorage) {
        return null;
    }

    return {
        title: localStorage.getItem('regular_spot_form.title'),
        description: localStorage.getItem('regular_spot_form.description'),
        tags: JSON.parse(localStorage.getItem('regular_spot_form.tags')),
        usefulLink: localStorage.getItem('regular_spot_form.usefulLink'),
        location: JSON.parse(localStorage.getItem('regular_spot_form.location')),
    }
}

export const retrieveChallengeForm = () => {
    if (!localStorage) {
        return null;
    }

    return {
        title: localStorage.getItem('challenge_spot_form.title'),
        description: localStorage.getItem('challenge_spot_form.description'),
        tags: JSON.parse(localStorage.getItem('challenge_spot_form.tags')),
        usefulLink: localStorage.getItem('challenge_spot_form.usefulLink'),
        location: JSON.parse(localStorage.getItem('challenge_spot_form.location')),
        task: localStorage.getItem('challenge_spot_form.task')
    }
}


export const retrieveHuntForm = () => {
    if (!localStorage) {
        return null;
    }

    return {
        title: localStorage.getItem('hunt_spot_form.title'),
        description: localStorage.getItem('hunt_spot_form.description'),
        tags: JSON.parse(localStorage.getItem('hunt_spot_form.tags')),
        usefulLink: localStorage.getItem('hunt_spot_form.usefulLink'),
        radius: parseInt(localStorage.getItem('hunt_spot_form.radius')),
        location: JSON.parse(localStorage.getItem('hunt_spot_form.location')),
    }
}

export const clearRegularForm = () => {
    if (!localStorage) {
        return null;
    }

    localStorage.removeItem('regular_spot_form.title');
    localStorage.removeItem('regular_spot_form.description');
    localStorage.removeItem('regular_spot_form.tags');
    localStorage.removeItem('regular_spot_form.usefulLink');
    localStorage.removeItem('regular_spot_form.location');
}

export const clearChallengeForm = () => {
    if (!localStorage) {
        return null;
    }

    localStorage.removeItem('challenge_spot_form.title');
    localStorage.removeItem('challenge_spot_form.description');
    localStorage.removeItem('challenge_spot_form.tags');
    localStorage.removeItem('challenge_spot_form.usefulLink');
    localStorage.removeItem('challenge_spot_form.location');
    localStorage.removeItem('challenge_spot_form.task');
}

export const clearHuntForm = () => {
    if (!localStorage) {
        return null;
    }

    localStorage.removeItem('hunt_spot_form.title');
    localStorage.removeItem('hunt_spot_form.description');
    localStorage.removeItem('hunt_spot_form.tags');
    localStorage.removeItem('hunt_spot_form.usefulLink');
    localStorage.removeItem('hunt_spot_form.radius');
    localStorage.removeItem('hunt_spot_form.location');
}

export const saveMapFilter = (
    spotTypes: string[], 
    tags: string[], 
    visited: VisitedFilter, 
    favorite: FavoriteFilter,
    pinType: PinTypeFilter,
    location: GeoPoint,
    zoom: number
) => {
    if (!localStorage) {
        return;
    }
    localStorage.setItem('map_filter_form.spotTypes', JSON.stringify(spotTypes));
    localStorage.setItem('map_filter_form.tags', JSON.stringify(tags));
    localStorage.setItem('map_filter_form.visited', visited);
    localStorage.setItem('map_filter_form.favorite', favorite);
    localStorage.setItem('map_filter_form.pinType', pinType);
    localStorage.setItem('map_filter_form.location', JSON.stringify(location));
    localStorage.setItem('map_filter_form.zoom', zoom.toString());
}

export const retrieveMapFilters = (): MapFilters => {
    if (!localStorage) {
        return null;
    }

    return {
        spotTypes: JSON.parse(localStorage.getItem('map_filter_form.spotTypes')),
        tags: JSON.parse(localStorage.getItem('map_filter_form.tags')),
        // @ts-ignore
        visited: localStorage.getItem('map_filter_form.visited'),
        // @ts-ignore
        favorite: localStorage.getItem('map_filter_form.favorite'),
        // @ts-ignore
        pinType: localStorage.getItem('map_filter_form.pinType'),
        location: JSON.parse(localStorage.getItem('map_filter_form.location')),
        zoom: parseInt(localStorage.getItem('map_filter_form.zoom')),
    }
}

export const clearMapFilter = () => {
    if (!localStorage) {
        return null;
    }

    localStorage.removeItem('map_filter_form.spotTypes');
    localStorage.removeItem('map_filter_form.tags');
    localStorage.removeItem('map_filter_form.visited');
    localStorage.removeItem('map_filter_form.favorite');
    localStorage.removeItem('map_filter_form.pinType');
    localStorage.removeItem('map_filter_form.location');
    localStorage.removeItem('map_filter_form.zoom');
}