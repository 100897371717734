import React from 'react';
import { SINGLE_SPOT_ROUTE } from '../../../base/routes';
import { Spot } from '../../../base/types';

let getImage = (props: Spot) => props.thumbnail?.url.replace('graphq', 'parse') 
    ?? props.image?.url.replace('graphq', 'parse') 
    ?? '/img/placeholder.jpg';

const RowCard = (props: Spot) => { 
  return <div className="card">
    <img className="card-img-top" src={getImage(props)} alt={props.title} />
    <a href={SINGLE_SPOT_ROUTE.replace(':id', props.objectId)}>
      <div className="card-img-overlay">
        <div className="card-body">
          <div className="metrics">
            <i className="fas fa-check"></i>{props.checkins_count}
            <i className="ml-2 far fa-heart"></i>{props.likes_count} 
          </div>
          <h6 className="card-title">{props.title}</h6>
        </div>
      </div>
    </a>
  </div>;
}

export default RowCard;