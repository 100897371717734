import Parse from 'parse';
import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/browser";

export const logAnalytics = async(name: string, data: object) => {
    if (process.env.NODE_ENV !== 'development') {
        try {
            await Parse.Analytics.track(name, data);
            await mixpanel.track(name, data);
        } catch (err) {
            Sentry.captureException(err);
        }
    }
}

export const logError = (err) => {
    if (process.env.NODE_ENV !== 'development') {
        Sentry.captureException(err);
    }
}

export const logErrorMessage = (message: string) => {
    if (process.env.NODE_ENV !== 'development') {
        Sentry.captureMessage(message);
    }
}