import React, { ReactChild, ReactChildren } from 'react';
import * as Sentry from "@sentry/browser";
import PageWithNavbar from './PageWithNavbar';

interface Props {
  children: ReactChild | ReactChildren
  error?: Error
}

const PageWithError = (props: Props) => {
  if (props.error) {
    Sentry.captureException(props.error);
  }

  return <PageWithNavbar>
    <div className="container m-4 mx-auto">
      <div className="alert border border-dark border-rounded text-center bg-white" role="alert">
        {props.children}
      </div>
    </div>
  </PageWithNavbar>;
}

export default PageWithError;