import React from 'react';

import L, { LatLngTuple } from 'leaflet';
import { Circle, MapContainer, Marker, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import { Spot } from '../../../base/types';
import { getIconUrl } from '../../../base/helpers';

type Props = {
  spot: Spot
}

let getIcon = (type: string) => {
  return L.icon({
      iconUrl: getIconUrl(type, false),
      iconSize: [35, 48], // size of the icon
      iconAnchor: [15, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [2, -37] // point from which the popup should open relative to the iconAnchor
    });
}

const PreviewMap = (props: Props) => {
  const location: LatLngTuple = [props.spot.location.latitude, props.spot.location.longitude];  
  const marker = <Marker position={location} icon={getIcon(props.spot.type)}></Marker>;

  return <MapContainer 
    center={location}
    zoom={14}
    className="h-100 rounded"
    style={{minHeight: 180}}>
      <TileLayer
          url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          accessToken="pk.eyJ1IjoiMTl0aCIsImEiOiJjamxubWVhc2MxZnE4M3ZsNGk5a2FnMTJ1In0.RFerh074_4AnixvpRRruRA"
          maxZoom={18}
          minZoom={4}
          id="mapbox/streets-v11"
      />
      { props.spot.radius > 0 
        ? <Circle center={location} pathOptions={{ fillColor: 'blue', opacity: 0 }} radius={props.spot.radius} />
        : marker
      }
  </MapContainer>
}

export default PreviewMap;
