import React, { useMemo, useState } from 'react';

import { LatLng, LatLngBounds } from 'leaflet';
import { CircleMarker, MapContainer, Popup, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import { GeoPoint, Spot } from '../../../base/types';

import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';

import LeafletMarker from './LeafletMarker';
import { LocateButton } from './LocateButton';
import { MAP_PIN_TYPE_PIN, MAP_PIN_TYPE_THUMBNAIL } from '../../../base/constants';
import LeafletHash from '../LeafletHash';

type Props = {
  spots: Spot[]
  visitedSpotIds: string[]
  favoriteSpotIds: string[]
  onMapCreated: (latLngBounds: LatLngBounds) => void
  onBoundingBoxChange: (latLngBounds: LatLngBounds) => void
  onCoordsChange: (latLng: LatLng, zoom: number) => void
  defaultCoords: GeoPoint
  defaultZoom: number
  showThumbnailIcons?: boolean 
  pinTypeFilter: typeof MAP_PIN_TYPE_PIN|typeof MAP_PIN_TYPE_THUMBNAIL
  setPinTypeFilter: (pinType: typeof MAP_PIN_TYPE_PIN|typeof MAP_PIN_TYPE_THUMBNAIL) => void
}

const leafletHash = new LeafletHash();

const LeafletMap = (props: Props) => {

  const [ userLatLng, setUserLatLng ] = useState<LatLng>(null);
  const [ userAccuracy, setUserAccuracy ] = useState<number>(null);

  const markers = useMemo(() => props.spots.map(spot => {
    return <LeafletMarker 
      spot={spot} 
      key={spot.objectId}
      showThumbnailIcon={props.showThumbnailIcons ?? false}
      isVisited={props.visitedSpotIds.includes(spot.id)}
      isFavorite={props.favoriteSpotIds.includes(spot.objectId)} />
  }), [props.spots, props.showThumbnailIcons]);

  return <MapContainer 
    tap={false} // should fix Safari popup problem
    center={[props.defaultCoords.latitude, props.defaultCoords.longitude]}
    whenCreated={
      (map) => {
        let locationFromUrl = leafletHash.parseHash();
        if (locationFromUrl) {
          map.setView(locationFromUrl.center, locationFromUrl.zoom)
        }
        props.onMapCreated(map.getBounds());
        map.on('zoomend moveend', () => {
          props.onBoundingBoxChange(map.getBounds());
          props.onCoordsChange(map.getCenter(), map.getZoom());
          leafletHash.updateMapHash(map);
        });
        map.locate().on("locationfound", function (e) {
          setUserLatLng(e.latlng);
          setUserAccuracy(e.accuracy);
          leafletHash.updateMapHash(map);
        });
      }
    }
    zoom={props.defaultZoom}
    className="col h-100">
      <TileLayer
          url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          accessToken="pk.eyJ1IjoiMTl0aCIsImEiOiJjamxubWVhc2MxZnE4M3ZsNGk5a2FnMTJ1In0.RFerh074_4AnixvpRRruRA"
          maxZoom={18}
          minZoom={4}
          id="mapbox/streets-v11"
      />
      <LocateButton position="topleft"></LocateButton>

      <div className="leaflet-bottom leaflet-left">
        <div className="leaflet-control leaflet-bar p-2 bg-white rounded">
          <input 
            type="checkbox"
            className="align-middle"
            id="pin-type-switch" 
            checked={props.pinTypeFilter === MAP_PIN_TYPE_PIN} 
            required={true}
            onChange={() => props.setPinTypeFilter(props.pinTypeFilter === MAP_PIN_TYPE_PIN ? MAP_PIN_TYPE_THUMBNAIL : MAP_PIN_TYPE_PIN)}/>
          <label htmlFor="pin-type-switch" className="m-0">
            <img className="btn-img align-middle ml-1" src="img/pin-spot.png" alt="Map pin" />
          </label>
        </div>
      </div>

      <MarkerClusterGroup
        showCoverageOnHover={false}
        zoomToBoundsOnClick={true}
        animate={true}
        maxClusterRadius={30}>
        {markers}
      </MarkerClusterGroup>
      
      { userLatLng && userAccuracy <= 50 &&
        <CircleMarker 
          center={userLatLng} 
          radius={10}
          color={'white'} 
          fillColor={'red'}>
          <Popup autoPan={false}>
              <div className="p-2" style={{width: 150}}>
                <div>{userLatLng.lat}, {userLatLng.lng}</div>
                <div>Accuracy: {userAccuracy ^ 0}</div>
              </div>
          </Popup>
        </CircleMarker>
      }
  </MapContainer>
}

export default LeafletMap;
