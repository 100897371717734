import React, { useEffect,  useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { LatLngBounds } from 'leaflet';
import Parse from 'parse';

import './index.css';
import LeafletMap from './components/LeafletMap';
import Sidebar from './components/Sidebar';

import PageWithNavbar from '../../base/components/PageWithNavbar';
import PageErrorFetchingData from '../../base/components/PageErrorFetchingData';

import { FavoriteFilter, GeoPoint, PinTypeFilter, Spot, VisitedFilter } from '../../base/types';
import { getLocation } from '../../base/services/locationService';

import { GET_NEAR_SPOTS } from '../../base/queries';
import { SPOT_TYPES, MAP_PIN_TYPE_THUMBNAIL, DEFAULT_ZOOM } from '../../base/constants';
import { DEFAULT_COORDS } from '../../base/constants';
import { retrieveMapFilters, saveMapFilter } from '../../base/services/localStorageForm';
import { pipe } from '../../base/helpers';

type State = {
  all: Spot[],
  visitedSpotIds: string[],
  favoriteSpotIds: string[]
}

const Map = () => {
  /* State */
  const [ spots, setSpots ] = useState<State>({
    all: [],
    visitedSpotIds: [],
    favoriteSpotIds: []
  });

  /* Saved filters */
  let savedFilters = retrieveMapFilters();

  /* Filters */
  const [ typeFilter, setTypeFilter ] = useState<string[]>(savedFilters.spotTypes || []);
  const [tagsFilter, setTagsFilter] = useState<string[]>(savedFilters.tags || []);
  const [ visitedFilter, setVisitedFilter ] = useState<VisitedFilter>(savedFilters.visited || 'all');
  const [ pinTypeFilter, setPinTypeFilter ] = useState<PinTypeFilter>(savedFilters.pinType || MAP_PIN_TYPE_THUMBNAIL);
  const [ favoriteFilter, setFavoriteFilter ] = useState<FavoriteFilter>(savedFilters.favorite || 'all');

  /* Map */
  let [coords, setCoords] = useState<GeoPoint>(savedFilters.location || DEFAULT_COORDS)
  let [zoom, setZoom] = useState<number>(savedFilters.zoom || DEFAULT_ZOOM);
  const [boundingBox, setBoundingBox] = useState({
    bottomLeftLatitude: 0,
    bottomLeftLongitude: 0,
    upperRightLatitude: 0,
    upperRightLongitude: 0,
  });

  /* GraphQL query */
  const [fetchSpots, { loading, error, data }] = useLazyQuery(GET_NEAR_SPOTS, {
    variables: {
      types: typeFilter.length === 0 ? SPOT_TYPES : typeFilter,
      userId: Parse.User.current()?.id,
      bottomLeftLatitude:  boundingBox.bottomLeftLatitude,
      bottomLeftLongitude: boundingBox.bottomLeftLongitude,
      upperRightLatitude:  boundingBox.upperRightLatitude,
      upperRightLongitude: boundingBox.upperRightLongitude,
    },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
  });

  // initial set of coords
  useEffect(() => {
    getLocation().then(newCoords => {
      setCoords(newCoords);
      fetchSpots();
    });
  }, []);

  useMemo(() => {
    if(loading === false && data) {
      let { spots, checkIns, user: { favoriteSpots } } = data;

      spots = spots.edges.map((spot: {node: Spot}) => spot.node);
      let visitedSpotIds = checkIns.edges.map((checkIn: {node:{spot: Spot}}) => checkIn.node.spot.id);
      let favoriteSpotsIds = favoriteSpots.edges.map(spot => spot.node.objectId);
      setSpots({
        all: spots,
        visitedSpotIds: visitedSpotIds,
        favoriteSpotIds: favoriteSpotsIds
      });
    }
  }, [loading, data, setSpots])

  useEffect(() => {
    saveMapFilter(typeFilter, tagsFilter, visitedFilter, favoriteFilter, pinTypeFilter, coords, zoom);
  }, [typeFilter, tagsFilter, visitedFilter, favoriteFilter, pinTypeFilter, coords, zoom])

  /* Filters */
  const applyTagsFilter = (spots: Spot[]) => {
    if (tagsFilter.length === 0) {
      return spots;
    }

    return spots.filter(spot =>
      spot.tags.some(tag => tagsFilter.includes(tag.value))
    );
  };

  const applyVisitedFilter = (filteredSpots: Spot[]) => {
    if (visitedFilter === 'visited') {
      return filteredSpots.filter(spot => spots.visitedSpotIds.includes(spot.id));
    }
    if (visitedFilter === 'non-visited') {
      return filteredSpots.filter(spot => !spots.visitedSpotIds.includes(spot.id));
    }
    return filteredSpots;
  };

  const applyFavoriteFilter = (filteredSpots: Spot[]) => {
    if (favoriteFilter === 'favorite') {
      return filteredSpots.filter(spot => spots.favoriteSpotIds.includes(spot.objectId));
    }
    if (favoriteFilter === 'non-favorite') {
      return filteredSpots.filter(spot => !spots.favoriteSpotIds.includes(spot.objectId));
    }
    return filteredSpots;
  };

  const applyFilters = (spots: Spot[]) => pipe(
    applyFavoriteFilter,
    applyVisitedFilter,
    applyTagsFilter
  )(spots);

  /* Callbacks */
  let onTagsChange = (tags: string[]) => {
    setTagsFilter(tags);
  };

  let onTypeFilterToggle = (type: string) => {
    if (typeFilter.indexOf(type) > -1) {
      setTypeFilter(typeFilter.filter(t => t !== type));
    } else {
      setTypeFilter([...typeFilter, type]);
    }
  }

  let onMapBoxChange = (bounds: LatLngBounds) => {
    setBoundingBox({
        bottomLeftLatitude: bounds.getSouthWest().lat,
        bottomLeftLongitude: bounds.getSouthWest().lng,
        upperRightLatitude: bounds.getNorthEast().lat,
        upperRightLongitude: bounds.getNorthEast().lng,
    });
  }

  if (error) return <PageErrorFetchingData error={error} />;

  return <PageWithNavbar>
    <>
    {/* Top spacing on desktop */}
    <div className="d-none d-lg-block p-3"></div>

    {/* Map box */}
    <div className="container h-75 border rounded map-page bg-white">
      <div className="row h-100 p-0 shadow">
        {/* Map  */}
        <LeafletMap 
          spots={applyFilters(spots.all)}
          visitedSpotIds={spots.visitedSpotIds}
          favoriteSpotIds={spots.favoriteSpotIds}
          onMapCreated={(bounds) => onMapBoxChange(bounds)}
          onCoordsChange={(latLng, zoom) => {
            setCoords({
              latitude: latLng.lat, 
              longitude: latLng.lng
            });
            setZoom(zoom);
          }}
          onBoundingBoxChange={(bounds) => onMapBoxChange(bounds)}
          pinTypeFilter={pinTypeFilter}
          setPinTypeFilter={setPinTypeFilter}
          defaultCoords={coords}
          defaultZoom={zoom}
          showThumbnailIcons={pinTypeFilter === MAP_PIN_TYPE_THUMBNAIL} />
        {/* Filters */}
        <Sidebar
          tagsFilter={tagsFilter}
          onTagsChange={onTagsChange} 
          typeFilter={typeFilter}
          onTypeFilterToggle={onTypeFilterToggle}
          visitedFilter={visitedFilter}
          onVisitedFilterChange={(newVisitedFilter: VisitedFilter) => setVisitedFilter(newVisitedFilter)}
          favoriteFilter={favoriteFilter}
          onFavoriteFilterChange={(newFavoriteFilter: FavoriteFilter) => setFavoriteFilter(newFavoriteFilter)}
          />
      </div>
    </div>
    </>
  </PageWithNavbar>
}

export default Map;
