import React, { Component } from 'react'; 
import { Redirect } from 'react-router-dom';
import hello from 'hellojs';
import Parse, { User } from 'parse';
import Swal from 'sweetalert2';
import {Helmet} from "react-helmet";

import { HOME_ROUTE } from '../../base/routes';
import './index.css';
import SignUpButtons from './components/SignUpButtons';
import { logAnalytics, logError } from '../../base/services/analyticsService';
import Spinner from '../../base/components/Spinner';
import { isUnsupportedBrowser } from '../../base/helpers';

function isImageEmptyOrDefault(image: string) {
  if (image === null || image === '') {
    return true;
  }

  let isDefault = image.indexOf('AAAAAAAAAAI/AAAAAAAAAAA') !== -1;
  return isDefault;
}

interface LandingState {
  isLoggedIn: boolean
  isLoading: boolean
}

export default class Landing extends Component<{}, LandingState> {  

  test = 0;
  state: LandingState = {
    isLoggedIn: !!Parse.User.current(),
    isLoading: false
  }
  
  constructor(props) {
    
    console.log('constructor');
    super(props);
    // callback on successfull Google login
    hello.on('auth.login', (auth) => {
      // this is dirty hack, because callback is called twice by hellojs
      if (window['authLogin']) return;
      window['authLogin'] = true;

      if (auth.network === 'facebook') {
        this.onFacebookCallback(auth);
      } else if (auth.network === 'google') {
        this.onGoogleCallback(auth);
      }
    });
  }

  redirectAfterLoggedIn() {

    let routeBeforeLogin = sessionStorage.getItem('route_before_login');
    console.log('routeBeforeLogin ' + routeBeforeLogin);
    if (routeBeforeLogin) {
      sessionStorage.removeItem('route_before_login');
    }
    window.location.href = routeBeforeLogin ?? HOME_ROUTE;
  }

  async onFacebookCallback(auth: hello.HelloJSEventArgument) {
  
    this.setState({isLoading: true});
    const profile = await hello(auth.network).api('me', {
      scope:'email',
      force: true
    });

    if (!profile.email) {
      Swal.fire({icon: 'error', text: 'Email address is not granted by social login. Please try again.'});
      hello(auth.network).logout();
      return;
    }

    const authData = {
      id: profile.id,
      access_token: auth.authResponse.access_token,
      email: profile.email,
      network: auth.network
    };
    // profile.email = profile.email.replace('@', '_facebook@');
    await this.loginUser(auth.network, authData, profile);

    this.setState({isLoading: false});
  }

  async onGoogleCallback(auth: hello.HelloJSEventArgument) {
  
    this.setState({isLoading: true});
    if (!auth.authResponse) {
      return;
    }
    const profile = await hello(auth.network).api('me');
    const authData = {
      id: profile.id,
      id_token: auth.authResponse.id_token,
      email: profile.email,
      network: auth.network
    };
    await this.loginUser(auth.network, authData, profile);
    this.setState({isLoading: false});
  }

  async loginUser(network: string, authData: any, profile: any) {
    // log in
    try {
      const user = await Parse.User.logInWith(network, { authData: authData});
      if (user.existed() === false) {
        // sign up
        await this.createUser(user, profile);
        logAnalytics(network + 'SignupSuccess', {user: profile.name, email: profile.email});
      } else {
        logAnalytics(network + 'LoginSuccess', {user: profile.name, email: profile.email});
      }
      // this.setState({isLoggedIn: true});
      this.redirectAfterLoggedIn();
      return;
    } catch(err) {
      hello(network).logout();
      if (err.message) {
        Swal.fire({icon: 'error', text: err.message});
      }
      logError(err);
      logAnalytics(network + 'LoginError', {message: err.message, user: profile.name, email: profile.email});
    }
  }

  onGoogleSignUp () {
    const helloGoogleLogin = () => hello.login('google', {scope: 'email,profile', response_type: 'id_token token', display: 'page'});
    hello('google').logout().then(helloGoogleLogin, helloGoogleLogin);
  }

  onFacebookSignUp () {
    const helloFacebookLogin = () => hello.login('facebook', {scope: 'email,public_profile', display: 'page'});
    hello('facebook').logout().then(helloFacebookLogin, helloFacebookLogin);
  }

  async createUser(user: User, profile) {

    user.set('displayName', profile.name);
    user.set('email', profile.email);

    if (!isImageEmptyOrDefault(profile.picture)) {
      try {
        let parseFile = new Parse.File('profile.jpg', {uri: profile.picture.replace('96', '256')});
        await parseFile.save();
        user.set('image', parseFile);
      } catch(e) {
        console.error(e);
      }
    }

    await user.save();
  }

  render() {
    if (this.state.isLoggedIn) window.location.href = HOME_ROUTE;
    if (this.state.isLoading) return <Spinner />;

    return (
      <div id="landing-page" className="container-fluid">
          <Helmet>
            <title>SelfieHunt - Hunt down most instagrammable locations, do challenges, level up your selfie skills</title>
            <meta name="description" content="Web app for sharing exciting selfie locations from all across the world with a crisp of gamification" />
            
            <meta property="og:title" content="SelfieHunt - Hunt down most instagrammable locations, do challenges, level up your selfie skills" />
            <meta property="og:description" content="Web app for sharing exciting selfie locations from all across the world with a crisp of gamification" />
            <meta property="og:image" content={window.location.href + 'img/duck-selfies.jpg'} />
        </Helmet>
        <div className="container p-3 text-center">
          <div className="row mx-4 no-gutters">
            { isUnsupportedBrowser() && 
              <div className="alert alert-danger alert-outline-black" role="alert">
                <i className="fas fa-exclamation-triangle"></i> App open in WebView, Google authenthication may not work. For better experience use Chrome, Safari or Firefox browser.
              </div>
            }
            <div className="col-12 mb-sm-4 pt-2">
              <img className="logo mx-auto" src="/img/selfiehunt.png" alt="Are you ready for adventure?" />
            </div>
            <div className="col-12 col-sm-6">
              <img className="land-img" src="/img/selfiehuntpath.png" alt="SelfieHunt path with map pins and points of interest" />
            </div>
            <div className="col-12 col-sm-6 my-auto">
              <h2 className="cover-heading">Hunt down most instagrammable locations, do challenges, level up your selfie skills</h2>
              <div className="lead">
                <div className="mb-2 small">Sign Up / Sign In</div>
                <SignUpButtons 
                  fbSignUp={async () => this.onFacebookSignUp()}
                  googleSignUp={async () => this.onGoogleSignUp()}/>
                <div className="small"><small>By proceeding you agree to our <u><a href="/sh-privacy-policy.pdf">Privacy Policy</a></u></small></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
