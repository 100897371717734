import { MAP_ROUTE } from "./routes";
import { GeoPoint } from "./types";

export const formatDate = (dateTime: string) => {
    var d = new Date(dateTime),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;

    return [year, month, day].join('-');
}

export const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export const formatCoordinate = (coordinate: number) => {
    if (coordinate.toString().length > coordinate.toFixed(6).toString().length) {
        return coordinate.toFixed(6);
    }
    return coordinate;
}

export const formatStringCoordinate = (coordinate: string) => {
    const matchResult = coordinate.match(/(-?\d+\.?\d{0,6})/);
    if (matchResult === null) {
        return '0';
    }
    return matchResult[0];
}

export const formatLocation = (location: GeoPoint) => {
    return formatCoordinate(location.latitude).toString()
        + ', '
        + formatCoordinate(location.longitude).toString();
}

export const buildGoogleMapsLink = (location: GeoPoint) => {
    return `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`;
}

export const buildWazeLink = (location: GeoPoint) => {
    return `https://waze.com/ul?ll=${location.latitude},${location.longitude}`;
}

export const buildMapLocationLink = (location: GeoPoint) => {
    return `${MAP_ROUTE}#15/${location.latitude}/${location.longitude}`;
}

export const slugify = (text: string) => {
    text = text.replace(/^\s+|\s+$/g, ''); // trim
    text = text.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    text = text.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return text;
}

export const getIconUrl = (type: string, isVisited: boolean) => {
    if (isVisited) {
        return '/img/pin-visited.png';
    }
    
    if (type === 'challenge') {
        return  '/img/pin-challenge.png';
    } else if (type === 'hunt') {
        return  '/img/pin-hunt.png';
    }
    return '/img/pin-spot.png';
};

export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x);

export const isUnsupportedBrowser = () => {
    return navigator.userAgent.includes('WebView')
      || navigator.userAgent.includes('Instagram')
      || navigator.userAgent.includes('FBAN')
      || navigator.userAgent.includes('FBAV')
      || navigator.userAgent.includes('FB_IAB');
}

export const redirectIfWebView = () => {
    if (isUnsupportedBrowser()) {
        const isAndroid = /(android)/i.test(navigator.userAgent);
        if (isAndroid) {
          document.write(`<a target="_system" class="d-none" href="${window.location.href}?nibrowser=no" download id="open-browser-url">Sadly Google auth is not working in FB/Instagram messenger</a>`);
          window.stop();
          let input = document.getElementById('open-browser-url');
          if (input) {
              input.click();
          }
        }
      }
}