import React, { useState } from 'react';
import { SPOT_TYPE_CHALLENGE, SPOT_TYPE_HUNT, SPOT_TYPE_PROMO, SPOT_TYPE_REGULAR } from '../constants';

import { SINGLE_SPOT_ROUTE } from '../routes';
import { showFullImagePopup } from "../services/popupService";
import { Spot } from '../types';

type Props = {
  spot: Spot
  smallScreenBigPicture?: boolean
  deleteBtnHandler?: (spotId: string) => Promise<any>
}

const SpotListItem = (props: Props) => {
    const [hidden, hide] = useState(false);

    let imageSrc = props.spot.thumbnail?.url.replace('graphq', 'parse')
        ?? props.spot.image?.url.replace('graphq', 'parse') 
        ?? '/img/placeholder.jpg';

    if (hidden) {
      return <div className="card border-0 pt-3 py-sm-3">
        Item removed.
      </div>
    }

    return <div className="card border-0 pt-3 py-sm-3">
    <div className="row no-gutters">
      <div className={(props.smallScreenBigPicture ? 'col-5' : '') + ' col-sm-4 text-center align-self-center'}>
        <img className="card-img mw-75" loading="lazy"
          src={imageSrc} 
          alt={props.spot.title} />
        <div className="card-img-overlay-top"
          style={{cursor: 'pointer'}}
          onClick={() => showFullImagePopup(props.spot.image?.url.replace('graphq', 'parse'))}>
          <div className="p-2 float-right">
            {renderSpotTypeIcon(props.spot.type)}
          </div>
        </div>
      </div>
      <div className="col align-self-center">
        <div className="card-body px-0 px-sm-3 py-sm-0">
          <h5 className="card-title mb-1">
            <a className="link stretched-link" href={SINGLE_SPOT_ROUTE.replace(':id', props.spot.objectId)}>
              {props.spot.title}
            </a>
          </h5>
          <div className="metrics">
            <i className="fas fa-check"></i>{props.spot.checkins_count}
            <i className="ml-2 far fa-heart"></i>{props.spot.likes_count}
          </div>
          <div className="tags mt-2 text-muted">
            { props.spot.tags.map(tag => <small>#{tag.value} </small>) }
          </div>
        </div>
      </div>
      { typeof props.deleteBtnHandler === 'function' && 
        <div className="col-12 col-sm-1 text-center align-self-center">
          <a href="#" className="btn btn-sm" onClick={(event) => {event.preventDefault(); props.deleteBtnHandler(props.spot.objectId).then(() => hide(true))}}>
            <i className="fas fa-minus-circle"></i>
          </a>          
        </div>
      }
    </div>
  </div>
}

export default SpotListItem;

function renderSpotTypeIcon(type: string): React.ReactNode {
  if (type === SPOT_TYPE_CHALLENGE) {
    return <img className="spot-list-icon" alt="Challenge spot type" title="Challenge spot type" src="/img/circle-challenge.png" />
  }
  if (type === SPOT_TYPE_HUNT) {
    return <img className="spot-list-icon" alt="Hunt spot type" title="Hunt spot type" src="/img/circle-hunt.png" />
  }
  if (type === SPOT_TYPE_PROMO) {
    return <img className="spot-list-icon" alt="Promotional spot type" title="Promotional spot type" src="/img/circle-promo.png" />
  }
  return <img className="spot-list-icon" alt="Regular spot type" title="Regular spot type" src="/img/circle-spot.png" />;
}
