export const LANDING_ROUTE = '/';
export const HOME_ROUTE = '/home';
export const MAP_ROUTE = '/map';
export const HOW_TO_PLAY_ROUTE = '/how-to-play';

export const SUBMIT_REGULAR_SPOT_ROUTE = '/submit-regular-spot';
export const SUBMIT_CHALLENGE_SPOT_ROUTE = '/submit-challenge-spot';
export const SUBMIT_HUNT_SPOT_ROUTE = '/submit-hunt-spot';

export const CHECK_IN_ROUTE = '/submit-check-in/:id';

export const MY_PROFILE_ROUTE = '/my-profile';
export const PROFILE_ROUTE = '/profile/:id';
export const EDIT_PROFILE = '/edit-profile';

export const SEARCH_ROUTE = '/search/:query?';
export const SINGLE_SPOT_ROUTE = '/spot/:id';
export const HOW_TO_SUBMIT_SPOT = '/how-to-submit-spot';
export const FAVORITE_SPOTS_ROUTE = '/favorites';