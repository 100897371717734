import React from "react";
import moment from 'moment'

import { CheckIn } from "../../../base/types";
import { showFullImagePopup } from "../../../base/services/popupService";
import { PROFILE_ROUTE } from "../../../base/routes";

const getImage = (checkIn: CheckIn) => checkIn.thumbnail?.url.replace('graphq', 'parse')
    ?? checkIn.image?.url.replace('graphq', 'parse') 
    ?? '/img/placeholder.jpg';

const CheckInCard = (props: {checkIn: CheckIn}) => <div className="card border-only-bottom pt-3 py-sm-3">
    <div className="row no-gutters">
        <div className="col-sm-4 text-center align-self-center">
            <img loading="lazy" className="card-img" style={{cursor: 'pointer'}}
                src={getImage(props.checkIn)}
                alt={props.checkIn.author.displayName} 
                onClick={() => showFullImagePopup(props.checkIn.image?.url.replace('graphq', 'parse'))} />
        </div>
        <div className="col align-self-center">
            <div className="card-body px-0 px-sm-3 py-sm-0">
                <p className="card-title mb-1">
                    <a className="link" href={PROFILE_ROUTE.replace(':id', props.checkIn.author.objectId)} >{props.checkIn.author.displayName}</a><small className="text-muted ml-1">on {moment(props.checkIn.createdAt).format('MMMM Do, YYYY')}</small>
                </p>
                <p className="card-text">{props.checkIn.comment}</p>
                {props.checkIn.liked && 
                    <p className="card-text">
                        <small><i className="mr-2 far fa-heart"></i>liked the spot</small>
                    </p>
                }
            </div>
        </div>
    </div>
</div>;

export default CheckInCard;
