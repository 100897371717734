import React, { useEffect } from 'react';

import L, { LatLng, LatLngTuple } from 'leaflet';
import { Circle, MapContainer, Marker, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import { GeoPoint } from '../../../base/types';
import { LocateButton } from '../../../page/MapBrowser/components/LocateButton';
import { getIconUrl } from '../../helpers';

let getIcon = (type: string) => {
  return L.icon({
      iconUrl: getIconUrl(type, false),
      iconSize: [35, 48], // size of the icon
      iconAnchor: [18, 45], // point of the icon which will correspond to marker's location
      popupAnchor: [2, -37] // point from which the popup should open relative to the iconAnchor
    });
}

type Props = {
  location: GeoPoint,
  type?: string,
  radius?: number
  onLocationChange: (newLocation: GeoPoint) => void
}

let onLocationChange = (point: LatLngTuple) => {};

const LocationPicker = (props: Props) => {

  const location: LatLngTuple = [props.location.latitude, props.location.longitude];  
  const marker = <Marker 
    position={location} 
    icon={getIcon(props.type || 'regular')} 
    draggable={true}
    eventHandlers={{
      'dragend': (e) => {
        const latLng = e.target.getLatLng();
        props.onLocationChange({
          latitude: latLng.lat,
          longitude: latLng.lng,
        })
      }
    }}>
  </Marker>;

  useEffect(() => {
    if (!isNaN(props.location.latitude) && !isNaN(props.location.longitude)) {
      onLocationChange([props.location.latitude, props.location.longitude]);
    }
  });

  return <MapContainer 
    center={location}
    zoom={15}
    className="h-100 w-100 rounded"
    whenCreated={
      (map) => {
        onLocationChange = (point: LatLngTuple) => {
          map.flyTo(point);
        }
        map.on('click', (event: {latlng: LatLng}) => {
          props.onLocationChange({
            latitude: event.latlng.lat,
            longitude: event.latlng.lng,
          })
        })
        map.on('locationfound', (event: {latlng: LatLng}) => {
          props.onLocationChange({
            latitude: event.latlng.lat,
            longitude: event.latlng.lng,
          })
        })
      }
    }>
      <TileLayer
          url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          accessToken="pk.eyJ1IjoiMTl0aCIsImEiOiJjamxubWVhc2MxZnE4M3ZsNGk5a2FnMTJ1In0.RFerh074_4AnixvpRRruRA"
          maxZoom={18}
          minZoom={4}
          id="mapbox/streets-v11"
      />
      <LocateButton/>
      
      { props.radius > 0
        ? <Circle center={location} pathOptions={{ fillColor: 'blue', opacity: 0 }} radius={props.radius} />
        : marker
      }

  </MapContainer>
}

export default LocationPicker;
